@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.right-bar-pane {
  background-color: #e9e8e7 !important;
  border-radius: 6px !important;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2) !important;
  cursor: default;
  margin-bottom: unset !important;
  padding: 10px 7px 10px 7px !important;
  position: absolute !important;
  right: 16px;
  top: 60px;
  width: 380px; }
  .right-bar-pane__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    padding-right: 5px; }
    .right-bar-pane__header-title {
      flex: 1;
      font-weight: 700;
      font-size: 14px; }
    .right-bar-pane__header img {
      width: 10px;
      height: 10px;
      cursor: pointer;
      margin-top: 5px;
      margin-left: 5px; }
