@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
#map-view-wrapper {
  flex: 1; }
  #map-view-wrapper .esri-view-surface {
    z-index: 0; }
  #map-view-wrapper .esri-view-surface:focus::after {
    outline: none;
    box-shadow: none; }
  #map-view-wrapper .esri-attribution__powered-by {
    display: none; }
  #map-view-wrapper .esri-attribution__sources {
    font-size: 11px;
    font-weight: 700;
    outline: none; }
  #map-view-wrapper .esri-slider {
    border-radius: 7px;
    cursor: pointer;
    height: 200px;
    margin-bottom: 15px;
    padding: 15px 0 15px 0;
    width: 42px; }
    #map-view-wrapper .esri-slider__label {
      margin-left: 5px;
      color: red;
      font-size: 10px;
      width: 80px;
      line-height: 1.2; }
    #map-view-wrapper .esri-slider__thumb {
      border-color: #38424a;
      height: 17px;
      width: 17px; }
      #map-view-wrapper .esri-slider__thumb:hover, #map-view-wrapper .esri-slider__thumb:focus {
        cursor: inherit;
        background-color: #38424a; }
    #map-view-wrapper .esri-slider__segment-0 {
      background-color: #38424a; }
    #map-view-wrapper .esri-slider__thumb, #map-view-wrapper .esri-slider__label {
      outline: none; }
