@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.qbe-data, .qbe-firestation, .qbe-hazards {
  margin-bottom: 20px; }
  .qbe-data label, .qbe-firestation label, .qbe-hazards label {
    font-size: 12px !important; }
  .qbe-data .ui.grid > .row, .qbe-firestation .ui.grid > .row, .qbe-hazards .ui.grid > .row {
    padding-top: 14px !important;
    padding-left: 14px !important;
    padding-bottom: unset !important; }
    .qbe-data .ui.grid > .row .field, .qbe-firestation .ui.grid > .row .field, .qbe-hazards .ui.grid > .row .field {
      display: flex;
      align-items: center; }
      .qbe-data .ui.grid > .row .field label, .qbe-firestation .ui.grid > .row .field label, .qbe-hazards .ui.grid > .row .field label {
        margin: unset; }
  .qbe-data .ui.label, .qbe-firestation .ui.label, .qbe-hazards .ui.label {
    background: unset;
    color: #636a70;
    font-size: 11px !important; }
