@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.add-edit-your-data-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }
  .add-edit-your-data-tools__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .add-edit-your-data-tools__button img {
      height: 20px; }
    .add-edit-your-data-tools__button:hover {
      opacity: 0.8; }
  .add-edit-your-data-tools__button.active {
    background-color: #38424a !important; }
  .add-edit-your-data-tools__button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important;
    border-radius: 10em !important; }
  .add-edit-your-data-tools__button:not(:nth-child(3n)) {
    margin-right: 6px !important; }
  .add-edit-your-data-tools__button.active {
    border-bottom: 2px solid #35d3f7 !important; }
  .add-edit-your-data-tools .ui.disabled.button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important; }

.add-edit-your-data__note {
  font-size: 12px;
  font-weight: 500;
  text-align: center; }
