@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
* {
  font-family: "Open Sans", sans-serif; }

.ui.button {
  font-weight: 600 !important; }

input, textarea, .dropdown {
  font-size: 12px !important; }
  input:focus, textarea:focus, .dropdown:focus {
    border-color: #333333 !important; }

label, input, textarea {
  color: #333333 !important; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container::before {
    align-items: center;
    content: url("~img/calendar-icon.svg");
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .react-datepicker-wrapper .react-datepicker__input-container > input {
    padding-left: 35px !important;
    text-align: left;
    height: 100%; }

.basemap-buttons {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  display: block !important; }
  .basemap-buttons__button {
    padding-top: 2px;
    padding-bottom: 2px;
    background: #ffffff !important;
    color: #5c5c5c;
    font-size: 13px !important;
    font-family: "Open Sans", sans-serif !important; }
    .basemap-buttons__button:not(:last-child) {
      border-right: 1px solid #eaeaea !important; }
    .basemap-buttons__button.active {
      background-color: #38424a !important;
      color: #ffffff !important; }
