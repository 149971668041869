@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.footer--full-screen {
  background-color: #39424a !important;
  border-radius: unset !important;
  margin: unset !important;
  padding: 5px; }

.footer__menu-item {
  cursor: pointer;
  color: #ffffff !important;
  font-size: 13px;
  font-weight: 600 !important; }
  .footer__menu-item::before {
    background: none !important; }
  .footer__menu-item:hover {
    opacity: 0.5 !important; }

.footer__menu-copyright {
  color: #ffffff !important; }
