@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.property {
  height: calc(100% - 25px); }
  .property__tab {
    height: inherit; }
    .property__tab .ui.menu {
      box-shadow: none !important;
      border: none !important;
      display: flex;
      margin: auto;
      justify-content: center;
      width: 60%; }
      .property__tab .ui.menu .item {
        background-color: #f7f7f7;
        color: #39424a;
        border: none;
        justify-content: center;
        width: 50%; }
      .property__tab .ui.menu > .item:last-child {
        border-radius: 0 .28571429rem .28571429rem 0; }
      .property__tab .ui.menu > .active.item, .property__tab .ui.menu > .active.item:hover {
        font-weight: 700 !important;
        color: white;
        background: #39424a; }
    .property__tab .ui.segment {
      height: inherit;
      overflow: hidden auto; }
      .property__tab .ui.segment::-webkit-scrollbar {
        width: 5px; }
    .property__tab .ui.tab {
      padding: unset;
      margin-top: 7px !important; }
    .property__tab .property-address {
      margin-top: 10px !important;
      text-align: center;
      padding: 0 10px !important;
      text-transform: uppercase !important; }
    .property__tab .reference, .property__tab .data-title {
      margin: 10px !important;
      text-align: left;
      padding: 0 10px !important; }
    .property__tab__placeholder {
      margin: 15px; }
