@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.color-picker {
  display: flex;
  position: relative;
  flex-direction: column; }
  .color-picker__swatch-button {
    background: #ffffff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    cursor: pointer;
    height: 30px;
    margin-top: 2px;
    padding: 5px;
    width: 100%; }
    .color-picker__swatch-button--required {
      border-color: #ff534c !important; }
    .color-picker__swatch-button--disabled {
      cursor: default; }
  .color-picker__popover {
    position: fixed;
    z-index: 2; }
    .color-picker__popover__cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .color-picker__popover .chrome-picker {
      border-radius: 5px !important;
      width: 188px !important;
      margin-top: 35px !important; }
      .color-picker__popover .chrome-picker input {
        padding: unset !important; }
      .color-picker__popover .chrome-picker .flexbox-fix:last-child {
        display: none !important; }
