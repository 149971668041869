@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.header--full-screen {
  border-radius: unset !important;
  margin: unset !important;
  padding: 0 5px 0 5px; }

.header__menu-item, .header__menu-brand {
  cursor: pointer;
  color: #7c8087 !important; }
  .header__menu-item::before, .header__menu-brand::before {
    background: none !important; }
  .header__menu-item > label, .header__menu-brand > label {
    padding-top: 6px; }
  .header__menu-item > img, .header__menu-brand > img {
    margin-right: 10px !important;
    width: unset !important; }

.header__menu-brand > img {
  height: 25px !important;
  margin-top: 0.3em !important; }

.header__menu-admin, .header__menu-map {
  display: flex !important;
  flex: 1 !important;
  justify-content: center;
  align-items: center;
  padding: unset !important; }
  .header__menu-admin::before, .header__menu-map::before {
    background: none !important; }

.header__menu-item {
  font-size: 13px;
  font-weight: 600 !important; }
  .header__menu-item__login-name {
    color: #39424a !important;
    pointer-events: none;
    font-weight: 600; }

.header__brand-label {
  font-weight: 600;
  color: #7c8087 !important; }
