@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");

.webmap {
  height:100vh;
}

.logout-frame {
  position: absolute;
  height: 0;
  width: 0;
}

.modals.dimmer {
  z-index: 2000;
}