@import "../../../scss/variables.scss";

.faq-footer {
  margin: unset !important;

  label {
    font-size: 14px;
  }

  .divider {
    margin: 7px 0 !important;
  }

  &__contact {
    padding: 55px 350px;

    h1 {
      color: $dark-grey !important;
    }

    &__left__title, &__right__title {
      color: $dark-grey !important;
      font-weight: 700 !important;
    }

    &__left {
      padding-right: 65px !important;

      &__name {
        display: block;
        margin-bottom: 5px !important;
      }

      &__number {
        color: $medium-red !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        margin-top: 5px !important;

        span {
          color: $dark-grey;
          font-size: 12px !important;
          font-weight: 600 !important;
          margin: 0 15px;
        }
      }
    }

    &__right {
      &__schedule {
        display: flex;
        flex-wrap: wrap;

        label {
          font-weight: 600 !important;
          flex-basis: 50%;
          margin-bottom: 2px;
        }

        &__name {
          color: $medium-grey !important;
        }
      }
    }
  }

  &__about {
    background-color: $dark-grey !important;
    padding: 45px 250px;

    p, label, h3 {
      color: $whitesmoke !important;
    }

    h3 {
      font-weight: 500 !important;
    }

    button {
      margin: 0 5px 15px 0 !important;
    }

    &__corelogic {
      font-weight: 500 !important;

      a {
        color: $tomato !important;
        text-decoration: underline !important;

        &:hover {
          color: $red !important;
        }
      }
    }

    &__data {
      margin-bottom: 10px !important;

      .icon {
        margin-right: 10px !important;
      }
    }

    &__contact-us, &__subscribe {
      color: $white !important;
      background-color: $dark-grey !important;
      border: 2px solid $white !important;
      border-radius: 5px !important;
      font-size: 13px !important;

      &:hover {
        opacity: 0.7 !important;
      }
    }

    &__divider {
      padding-top: 20px !important;

      > .column {
        padding: 0 100px !important;
      }
    }

    &__logo {
      height: 35px;
      width: 125px;
    }

    &__copyright {
      font-size: 12px !important;
      padding: unset !important;
      margin-bottom: 5px;
    }

    &__menu {
      font-size: 13px !important;
      padding-top: unset !important;
    }
  }
}