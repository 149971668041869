@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.property-details__fields {
  margin-top: 0 !important; }
  .property-details__fields > .row:first-child {
    padding-top: 0 !important; }

.property-details .row {
  padding-bottom: 10px !important; }

.property-details__legal-descriptions-label {
  margin-bottom: 3px !important; }

.property-details__parcel {
  margin-bottom: 5px !important; }
  .property-details__parcel > .row {
    padding-bottom: unset !important; }

.property-details label {
  font-size: 12px !important; }

.property-details .ui .column {
  padding: unset !important; }

.property-details .ui.label {
  line-height: 1.3em;
  background: unset;
  color: #636a70;
  font-size: 11px !important;
  margin: 5px 0 0 0 !important;
  padding: unset; }
