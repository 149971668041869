@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.configure-data-symbols-tabs .ui.menu {
  box-shadow: none !important;
  border: none !important;
  font-size: 12px !important;
  justify-content: center;
  margin: auto;
  width: 60%;
  padding: unset; }
  .configure-data-symbols-tabs .ui.menu .item {
    background-color: #e9e8e7;
    height: 25px !important;
    justify-content: center;
    width: 100px; }
  .configure-data-symbols-tabs .ui.menu .item:hover {
    background-color: #e9e8e7;
    font-weight: 700; }
  .configure-data-symbols-tabs .ui.menu > .active.item, .configure-data-symbols-tabs .ui.menu .active.item:hover {
    font-weight: 700 !important;
    color: white;
    background: #39424a; }
  .configure-data-symbols-tabs .ui.menu .item:last-child {
    border-radius: 0 .28571429rem .28571429rem 0; }

.configure-data-symbols-tabs .ui.segment {
  border: none !important;
  box-shadow: none !important;
  margin-top: 5px !important;
  padding: unset !important; }

.configure-data-symbols-tabs .ui.dropdown, .configure-data-symbols-tabs .ui.input > input {
  font-size: 12px !important; }
