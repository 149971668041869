.checkbox-group {
  margin-top: 4px; }
  .checkbox-group__main {
    font-size: 12px !important; }
  .checkbox-group__sublayers {
    margin: 3px 0 0 0;
    padding-left: 38px;
    font-size: 12px;
    list-style-type: none;
    display: flex;
    flex-direction: column; }
  .checkbox-group-item__sublayer {
    margin-bottom: 5px; }
    .checkbox-group-item__sublayer label {
      font-size: 12px !important; }
  .checkbox-group__title {
    display: flex;
    align-items: center;
    padding-bottom: 0 !important; }
    .checkbox-group__title label {
      font-size: 13px !important; }
    .checkbox-group__title.active {
      padding-bottom: 0 !important; }
  .checkbox-group__dropdown {
    font-family: Accordion, sans-serif !important; }
  .checkbox-group__content.active {
    padding-top: 5px !important; }
  .checkbox-group:not(.nested-group) .checkbox-group__sublayers .checkbox-group__title {
    padding: 0 !important; }

.nested-group {
  position: relative;
  left: -19px;
  margin: 0 0 5px !important; }
  .nested-group label {
    font-size: 12px !important; }
  .nested-group .checkbox-group__sublayers {
    padding-left: 26px; }
