@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
#add-user-wrapper {
  margin-left: 50px;
  margin-top: 60px; }
  #add-user-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #add-user-wrapper .create-user, #add-user-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 10px; }
  #add-user-wrapper .add-user {
    justify-content: space-between;
    margin-right: 50px; }
    #add-user-wrapper .add-user .validation-message {
      color: red;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px; }
  #add-user-wrapper .forms-wrapper {
    margin-left: 10px;
    margin-top: 20px; }
    #add-user-wrapper .forms-wrapper .field {
      display: flex;
      margin-bottom: 10px; }
      #add-user-wrapper .forms-wrapper .field .edit-field__title {
        height: 28px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center; }
      #add-user-wrapper .forms-wrapper .field .edit-field__value {
        height: 28px;
        width: 200px;
        border: 1px solid #a4bacc;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 0; }
      #add-user-wrapper .forms-wrapper .field .edit-field__checkbox {
        border: none !important; }
      #add-user-wrapper .forms-wrapper .field .required {
        border-color: red; }
  #add-user-wrapper .ui.dropdown {
    width: 50%;
    margin-left: 10px;
    max-height: 28px;
    min-height: 28px;
    align-items: center;
    display: flex;
    border: 1px solid #a4bacc;
    border-radius: unset; }
    #add-user-wrapper .ui.dropdown .dropdown.icon {
      font-family: Dropdown, sans-serif !important;
      top: 4px; }
