@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
#edit-details-wrapper {
  margin-left: 50px;
  margin-top: 60px; }
  #edit-details-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #edit-details-wrapper .update-details, #edit-details-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 10px; }
  #edit-details-wrapper .user-details {
    justify-content: space-between;
    margin-right: 50px; }
    #edit-details-wrapper .user-details .validation-message {
      color: red;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px; }
    #edit-details-wrapper .user-details .ui.dropdown {
      width: 50%;
      margin-left: 10px;
      max-height: 28px;
      min-height: 28px;
      align-items: center;
      display: flex;
      border: 1px solid #a4bacc;
      border-radius: unset; }
      #edit-details-wrapper .user-details .ui.dropdown .dropdown.icon {
        font-family: Dropdown, sans-serif !important;
        top: 4px; }
    #edit-details-wrapper .user-details .dropdown-wrapper {
      justify-content: space-between;
      display: flex; }
  #edit-details-wrapper .forms-wrapper {
    margin-left: 10px;
    margin-top: 20px; }
    #edit-details-wrapper .forms-wrapper .field {
      display: flex;
      margin-bottom: 10px; }
      #edit-details-wrapper .forms-wrapper .field .edit-field__title {
        height: 28px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center; }
      #edit-details-wrapper .forms-wrapper .field .edit-field__value {
        height: 28px;
        min-width: 280px;
        width: auto;
        border: 1px solid #a4bacc;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 0; }
      #edit-details-wrapper .forms-wrapper .field .disabled {
        border: none;
        color: #7c8087; }
      #edit-details-wrapper .forms-wrapper .field .required {
        border-color: red; }
      #edit-details-wrapper .forms-wrapper .field .address {
        margin-left: 10px; }
      #edit-details-wrapper .forms-wrapper .field .license {
        width: 600px; }
  #edit-details-wrapper .user-view .field .edit-field__value {
    width: 200px; }
  #edit-details-wrapper .corporation-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: -20px; }
