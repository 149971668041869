@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.poi-details {
  padding-bottom: 15px; }
  .poi-details label {
    font-size: 11px !important;
    font-weight: 700; }
  .poi-details .ui .column {
    padding: unset !important; }
  .poi-details .ui.label {
    background: unset;
    color: #636a70;
    font-size: 11px !important;
    margin: 5px 0 0 0 !important;
    padding: unset;
    display: block; }
