@import "../../../scss/variables.scss";

.faq-content {
  background-color: $whitesmoke !important;
  margin: unset !important;
  padding: 45px 312px;

  p {
    text-align: justify !important;
  }

  .table-header {
    background-color: $dark-grey !important;
    color: $white !important;
    letter-spacing: 1px;
    font-weight: 500 !important;
  }

  &__title {
    font-family: $sans-serif !important;
  }

  &__question {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    width: 100%;
  }

  &__answer {
    font-size: 13px;
    font-weight: 400;
  }
}