.notification-toast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px !important; }
  .notification-toast__icon {
    margin-right: 5px !important; }
  .notification-toast__message {
    font-size: 12px; }
  .notification-toast--hidden {
    display: none; }
  .notification-toast--show {
    display: flex; }
