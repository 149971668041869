@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.logout-modal__header {
  border-bottom: none !important;
  display: flex !important;
  justify-content: space-between;
  align-items: baseline; }

.logout-modal__close-icon {
  width: 15px;
  cursor: pointer; }

.logout-modal__content {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
  background: #0081ae !important;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff; }

.logout-modal__actions {
  background: #0081ae !important;
  border-top: none !important; }
