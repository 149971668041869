@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.identify-features-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }
  .identify-features-tools__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .identify-features-tools__button img {
      height: 20px; }
    .identify-features-tools__button:hover {
      opacity: 0.8; }
  .identify-features-tools__button.active {
    background-color: #38424a !important; }
  .identify-features-tools__button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important;
    border-radius: 10em !important; }
  .identify-features-tools__button:not(:nth-child(3n)) {
    margin-right: 6px !important; }
  .identify-features-tools__button.active {
    border-bottom: 2px solid #35d3f7 !important; }
  .identify-features-tools .ui.disabled.button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important; }

.identify-features__modal {
  border-radius: 8px !important; }
  .identify-features__modal .header {
    height: 38px;
    padding: 10px 13px 10px 13px !important;
    border-bottom: unset !important; }
    .identify-features__modal .header label {
      font-weight: 600;
      font-size: 15px; }
  .identify-features__modal .content {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
  .identify-features__modal .content {
    align-items: unset !important; }
    .identify-features__modal .content .list > .item {
      display: flex;
      align-items: center;
      height: 40px; }
      .identify-features__modal .content .list > .item img {
        border-radius: unset !important; }
  .identify-features__modal .actions {
    background: unset !important;
    border-top: unset !important;
    display: flex;
    justify-content: flex-end;
    padding-top: unset !important; }
    .identify-features__modal .actions button {
      border-radius: 20px;
      border: 2px solid #7c8087;
      background: unset;
      font-weight: 600 !important;
      height: 40px;
      margin: unset !important;
      width: 100px; }
      .identify-features__modal .actions button:hover {
        border-color: red; }
