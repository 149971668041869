@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.memorial-details {
  padding-top: 10px;
  padding-bottom: 10px; }
  .memorial-details__group {
    padding-bottom: 7px !important; }
    .memorial-details__group .row {
      padding-bottom: 0 !important; }
      .memorial-details__group .row:nth-of-type(4) {
        padding-top: 7px !important; }
    .memorial-details__group:not(:first-of-type) {
      padding-top: 7px !important; }
    .memorial-details__group:not(:last-of-type) {
      border-bottom: 1px solid #e9e8e7; }
  .memorial-details .row:first-child {
    padding-top: 0 !important; }
  .memorial-details .row:nth-of-type(2) {
    padding-top: 5px !important; }
  .memorial-details__subheader {
    padding-left: 0 !important; }
    .memorial-details__subheader .ui.header {
      font-size: 12px; }
  .memorial-details__field--inline {
    display: inline-flex;
    padding-left: 0 !important; }
  .memorial-details__field--inline .field {
    display: flex;
    align-items: center; }
  .memorial-details__label-container.row {
    padding-top: 7px !important;
    display: block !important; }
  .memorial-details__label {
    display: block;
    font-weight: normal !important;
    padding-bottom: 5px !important;
    font-size: 11px !important;
    line-height: 1.2em;
    letter-spacing: 0.1px; }
  .memorial-details label:not(.memorial-details__label) {
    font-size: 12px !important;
    font-weight: 700; }
  .memorial-details .ui.label {
    background: none; }
