@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.export {
  max-width: 350px !important; }

.export-data {
  font-size: 12px !important; }
  .export-data__modal {
    border-radius: 8px !important; }
    .export-data__modal .header {
      height: 38px;
      padding: 10px 13px 10px 13px !important;
      border-bottom: unset !important; }
      .export-data__modal .header label {
        font-weight: 600;
        font-size: 15px; }
    .export-data__modal .content {
      display: flex !important;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
    .export-data__modal .actions {
      background: unset !important;
      border-top: unset !important;
      display: flex;
      justify-content: space-between; }
      .export-data__modal .actions button {
        border-radius: 20px;
        border: 2px solid #7c8087;
        background: unset;
        font-weight: 600 !important;
        height: 40px;
        margin: unset !important;
        width: 100px; }
        .export-data__modal .actions button:hover {
          border-color: red; }
  .export-data .ui.basic.button {
    box-shadow: unset !important;
    text-decoration: underline;
    margin-left: 10px; }
  .export-data .ui.button {
    margin-top: 10px;
    height: 30px;
    padding: 8px; }
  .export-data .message {
    margin-top: 10px;
    margin-bottom: 5px; }
  .export-data .wrapper {
    display: flex;
    justify-content: center; }
