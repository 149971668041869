@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
#search-wrapper h3 {
  margin-left: 50px;
  margin-bottom: 20px; }

#search-wrapper th > .sort-icons-group {
  align-self: flex-end;
  margin-left: 10px; }
  #search-wrapper th > .sort-icons-group i {
    font-size: 16px;
    margin-right: 0 !important;
    font-family: 'Dropdown' !important; }

#search-wrapper .ui.grid {
  width: 100%;
  justify-content: flex-start;
  margin-left: 50px; }
  #search-wrapper .ui.grid .row.form-field__line {
    height: 60px;
    margin-bottom: 7px; }
  #search-wrapper .ui.grid .ui.form {
    display: flex; }
    #search-wrapper .ui.grid .ui.form .field {
      width: 400px;
      margin-right: 50px; }
      #search-wrapper .ui.grid .ui.form .field .name-wrapper {
        display: flex;
        flex-direction: row; }
      #search-wrapper .ui.grid .ui.form .field .dropdown-wrapper {
        justify-content: space-between;
        display: flex; }
  #search-wrapper .ui.grid .ui.dropdown > .dropdown.icon {
    font-family: Dropdown, sans-serif !important; }

#search-wrapper .ui.table {
  width: 100%; }
  #search-wrapper .ui.table th {
    padding: 7px;
    border-radius: 0 !important; }
  #search-wrapper .ui.table tr:hover {
    cursor: pointer; }
  #search-wrapper .ui.table tr td {
    color: #883639;
    font-weight: 600;
    font-size: 14px; }

#search-wrapper .search-users__search-controls {
  display: flex;
  justify-content: space-between;
  margin-left: 0 !important; }

#search-wrapper .search-users__table-wrapper {
  padding-top: 0;
  padding-bottom: 130px !important; }

#search-wrapper .search-users__table {
  margin: 0 0 10px 0 !important;
  width: 1245px; }

#search-wrapper .search-users--deleted {
  text-decoration: line-through; }

#search-wrapper .search-users__pagination {
  margin: 0 !important; }

#search-wrapper .pagination-control {
  border: none;
  box-shadow: none; }
  #search-wrapper .pagination-control a {
    text-decoration: underline; }
    #search-wrapper .pagination-control a:before {
      content: none; }
    #search-wrapper .pagination-control a.active {
      text-decoration: none;
      font-weight: 800; }
    #search-wrapper .pagination-control a[type="prevItem"], #search-wrapper .pagination-control a[type="nextItem"], #search-wrapper .pagination-control a[type="firstItem"], #search-wrapper .pagination-control a[type="lastItem"] {
      color: #883639;
      text-decoration: none; }
