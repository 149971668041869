@import '../../../scss/variables.scss';

.faq-header {
  &__menu-top {
    height: 92px;
    margin: unset !important;
    padding: 0 300px;

    > .item {
      color: $dark-grey !important;
      font-weight: 600 !important;
      margin: unset !important;
    }

    > .item:not(.faq-header__menu-top__logo) {
      margin-bottom: 15px !important;
    }

    &__logo {
      flex: 1 !important;
      padding-left: 0 !important;
      padding-top: 1.5em !important;

      img {
        height: 60px;
        width: 200px !important;
        margin: -.75em !important;
      }
    }
  }
}