.owners .row {
  padding: 8px 0 !important; }

.owners .ui.header {
  margin-bottom: 5px; }

.owners .other-info {
  display: flex;
  justify-content: space-between; }

.owners .icon {
  cursor: pointer; }

.owners label {
  font-size: 11px; }

.owners span {
  font-weight: 700; }
