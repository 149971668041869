.legend-group {

  &__contours {
    margin-top: 17px;
  }

  &__items {
    margin-top: 5px !important;
  }

  &__item {
    display: flex;
    align-items: center;

    img {
      height: 29px;
      width: 43px;
      margin-right: 10px;
    }

    &-header {
      font-size: 12px;
      font-weight: 600;
    }
  }
}