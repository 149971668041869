@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.layer-management__tabs .ui.menu {
  min-height: unset !important;
  font-size: 12px !important;
  height: 25px;
  border: none !important;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
  padding-right: 14px;
  padding-left: 14px; }
  .layer-management__tabs .ui.menu .item {
    border: none !important;
    flex-basis: 50% !important;
    justify-content: center;
    font-size: 11px;
    background: #f7f7f7;
    color: #39424a; }
  .layer-management__tabs .ui.menu .item:hover {
    font-weight: 700; }
  .layer-management__tabs .ui.menu .active.item, .layer-management__tabs .ui.menu .active.item:hover {
    font-weight: 700 !important;
    color: white;
    background: #39424a; }
  .layer-management__tabs .ui.menu .item:last-child {
    border-radius: 0 .28571429rem .28571429rem 0; }
  .layer-management__tabs .ui.menu .item:before {
    content: none !important; }

.layer-management__tab-item {
  padding: 0 0 5px 14px !important;
  border: none !important;
  margin-top: 5px !important;
  box-shadow: none !important;
  min-width: 314px; }

.layer-management-toolbox {
  max-height: 257px !important;
  overflow-y: auto; }
  .layer-management-toolbox .checkbox-group:first-of-type {
    margin-top: 0; }
  .layer-management-toolbox::-webkit-scrollbar {
    width: 5px; }

.layer-management-legends {
  max-height: 257px !important;
  overflow-y: auto; }
  .layer-management-legends img {
    display: block; }
  .layer-management-legends .legends__boundaries {
    height: 115px; }
  .layer-management-legends .legends__contour {
    margin-top: 10px;
    height: 120px; }
  .layer-management-legends::-webkit-scrollbar {
    width: 5px; }
