@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.toolbox {
  height: 100%;
  box-shadow: unset !important;
  margin-bottom: unset !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42px; }
  .toolbox__button-group {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    margin: 0 0 15px 0 !important; }
    .toolbox__button-group > :not(:last-child) {
      border-bottom: 1px solid #dce0e7 !important; }
    .toolbox__button-group > .toolbox__button {
      margin: unset !important;
      border-radius: unset !important; }
      .toolbox__button-group > .toolbox__button:first-child {
        border-top-left-radius: 7px !important;
        border-top-right-radius: 7px !important; }
      .toolbox__button-group > .toolbox__button:last-child {
        border-bottom-left-radius: 7px !important;
        border-bottom-right-radius: 7px !important; }
      .toolbox__button-group > .toolbox__button-configure-data-symbols img {
        height: 23px !important; }
  .toolbox__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .toolbox__button img {
      height: 20px; }
    .toolbox__button:hover {
      opacity: 0.8; }
  .toolbox__button.active {
    background-color: #38424a !important; }
  .toolbox__portal {
    border-radius: 7px !important;
    margin: unset !important;
    max-width: 203px;
    min-width: 203px !important;
    padding: 10px 10px 10px 7px !important;
    position: absolute !important;
    top: 73px !important;
    left: 66px !important;
    width: 100%; }
    .toolbox__portal.layer-management {
      width: auto;
      max-width: unset; }
    .toolbox__portal.configure-data-symbols {
      max-width: 500px !important; }
    .toolbox__portal__title {
      display: flex;
      align-items: baseline;
      margin-bottom: 15px; }
      .toolbox__portal__title label {
        flex: 1;
        font-size: 13px;
        font-weight: 600; }
      .toolbox__portal__title img {
        width: 10px;
        cursor: pointer; }
    .toolbox__portal__content__placeholder-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center; }
      .toolbox__portal__content__placeholder-icons > .placeholder {
        border-radius: 10em !important;
        height: 41px;
        margin-top: unset !important;
        margin-bottom: 15px !important;
        width: 42px !important; }
      .toolbox__portal__content__placeholder-icons > .placeholder:not(:nth-child(3n)) {
        margin-right: 6px !important; }
      .toolbox__portal__content__placeholder-icons > .placeholder > .image {
        height: unset !important; }
