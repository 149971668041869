@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.property-list {
  margin-top: 0 !important;
  padding: 18px 0 !important; }
  .property-list h5 {
    font-size: 12px;
    margin: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap; }
  .property-list .item {
    padding: unset !important; }
  .property-list__content {
    display: flex;
    align-items: center;
    padding: 0 10px; }
    .property-list__content .content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px; }
      .property-list__content .content img {
        border-radius: unset !important; }
    .property-list__content .ui.button {
      background-color: #ebedef !important;
      margin: unset !important; }
    .property-list__content .image {
      margin-right: 13px !important;
      margin-left: 3px !important; }
  .property-list__empty {
    margin-top: 10px !important;
    padding: 0 10px !important;
    text-transform: uppercase !important; }
  .property-list__search-query {
    margin-top: 0 !important; }
  .property-list__pagination-control {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px 0; }
    .property-list__pagination-control__results-display {
      margin: 0 20px; }
    .property-list__pagination-control__first-page {
      margin-right: 5px; }
    .property-list__pagination-control__last-page {
      margin-left: 5px; }
    .property-list__pagination-control__first-page, .property-list__pagination-control__prev-page, .property-list__pagination-control__last-page, .property-list__pagination-control__next-page {
      font-size: 18px;
      cursor: pointer; }
    .property-list__pagination-control__first-page i.icon, .property-list__pagination-control__last-page i.icon {
      width: 5px; }
  .property-list__placeholder {
    margin: 15px; }
