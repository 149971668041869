@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
#attributes-wrapper {
  margin-left: 50px;
  margin-top: 60px;
  font-weight: 600; }
  #attributes-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #attributes-wrapper .attribute-name-wrapper {
    display: flex;
    margin-bottom: 30px; }
    #attributes-wrapper .attribute-name-wrapper .attribute-name {
      margin-left: 20px; }
  #attributes-wrapper .update-attributes, #attributes-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 30px;
    margin-right: 20px; }
  #attributes-wrapper .edit-mode, #attributes-wrapper .theme {
    display: flex;
    align-items: center; }
    #attributes-wrapper .edit-mode .ui.dropdown, #attributes-wrapper .theme .ui.dropdown {
      font-weight: normal;
      width: 270px;
      margin-left: 10px;
      max-height: 28px;
      min-height: 28px;
      align-items: center;
      display: flex;
      border: 1px solid #a4bacc;
      border-radius: unset; }
      #attributes-wrapper .edit-mode .ui.dropdown .dropdown.icon, #attributes-wrapper .theme .ui.dropdown .dropdown.icon {
        font-family: Dropdown, sans-serif !important;
        top: 4px; }
  #attributes-wrapper .edit-mode .ui.dropdown {
    width: 180px; }
  #attributes-wrapper .additionals {
    display: flex;
    flex-direction: column; }
    #attributes-wrapper .additionals .ui.checkbox {
      font-weight: normal;
      margin-bottom: 20px; }
