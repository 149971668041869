@import "../../../scss/variables.scss";

#details-wrapper {
  margin-left: 50px;

  h3 {
    margin-bottom: 30px;

  }

  .ui.basic.button {
    text-decoration: underline;
    box-shadow: unset;
    color: $corn-flower-blue !important;
    padding: 0;
  }

  .user-details {
    .user-hierarchy-wrapper {
      display: flex;
      justify-content: space-between;
      margin-right: 50px;

      .hierarchy-buttons {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10px;

        div {
          display: flex;
          align-items: center;

          .ui.basic.button {
            margin: 20px;
            color: black !important;
            font-size: 16px;
          }

          .user-label {
            text-decoration: none;
          }
        }
      }
    }
  }

  .field {
    display: flex;
    margin-bottom: 10px;

    .user-field {
      &__title {
        height: 25px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center;
      }

      &__value {
        height: 25px;
        min-width: 280px;
        width: auto;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        background-color: rgb(241, 248, 251);
      }
    }

    .billing {
      width: 450px;
    }

    .license {
      min-width: 450px;
      width: auto;
    }
  }

  .edit-options {
    margin-top: 20px;

    button {
      margin-right: 20px;
      font-size: 16px;
    }
  }

  .service-attributes {
    h3 {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .cell-wrapper {
      display: flex;
      align-items: baseline;
      padding-top: 5px;

      div {
        margin-left: 4px;
        margin-right: 4px;
      }
    }

    button {
      margin-bottom: 10px;
    }

    .ui.table {
      width: 80%;

      td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
