@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.draw-and-measure-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }
  .draw-and-measure-tools__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .draw-and-measure-tools__button img {
      height: 20px; }
    .draw-and-measure-tools__button:hover {
      opacity: 0.8; }
  .draw-and-measure-tools__button.active {
    background-color: #38424a !important; }
  .draw-and-measure-tools__modal {
    border-radius: 8px !important; }
    .draw-and-measure-tools__modal .header {
      height: 38px;
      padding: 10px 13px 10px 13px !important;
      border-bottom: unset !important; }
      .draw-and-measure-tools__modal .header label {
        font-weight: 600;
        font-size: 15px; }
    .draw-and-measure-tools__modal .content {
      display: flex !important;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
    .draw-and-measure-tools__modal .actions {
      background: unset !important;
      border-top: unset !important;
      display: flex;
      justify-content: space-between; }
      .draw-and-measure-tools__modal .actions button {
        border-radius: 20px;
        border: 2px solid #7c8087;
        background: unset;
        font-weight: 600 !important;
        height: 40px;
        margin: unset !important;
        width: 100px; }
        .draw-and-measure-tools__modal .actions button:hover {
          border-color: red; }
  .draw-and-measure-tools__button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important;
    border-radius: 10em !important; }
  .draw-and-measure-tools__button:not(:nth-child(3n)) {
    margin-right: 6px !important; }
  .draw-and-measure-tools__button.active {
    border-bottom: 2px solid #35d3f7 !important; }
  .draw-and-measure-tools .ui.disabled.button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important; }
  .draw-and-measure-tools__popup-input-text {
    padding: 3px 7px 7px 7px !important; }
    .draw-and-measure-tools__popup-input-text__content {
      display: flex;
      flex-direction: column; }
      .draw-and-measure-tools__popup-input-text__content .header {
        font-size: 12px !important;
        font-weight: 600 !important;
        margin-bottom: 5px !important; }
      .draw-and-measure-tools__popup-input-text__content .body textArea {
        border-radius: 5px !important;
        font-size: 12px !important;
        padding: 8px !important;
        resize: none !important;
        width: 100% !important; }
        .draw-and-measure-tools__popup-input-text__content .body textArea:focus {
          outline: unset; }
        .draw-and-measure-tools__popup-input-text__content .body textArea::-webkit-scrollbar {
          width: 5px; }
      .draw-and-measure-tools__popup-input-text__content .actions {
        display: flex;
        justify-content: space-around;
        margin-top: 5px; }
        .draw-and-measure-tools__popup-input-text__content .actions button {
          background: unset;
          border-radius: 20px;
          border: 2px solid #7c8087;
          font-size: 11px;
          font-weight: 600 !important;
          height: 27px;
          margin: 0 10px 0 10px;
          padding: 5px;
          width: 57px; }
          .draw-and-measure-tools__popup-input-text__content .actions button:hover {
            border-color: red; }

.draw-and-measure-options label {
  font-size: 11px !important;
  font-weight: 600 !important; }
  .draw-and-measure-options label::before {
    border-color: #7c8087 !important; }

.draw-and-measure-options__input-width input, .draw-and-measure-options__input-font-size input {
  height: 30px !important;
  margin-top: 2px !important; }
