@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
.logout-modal__header {
  border-bottom: none !important;
  display: flex !important;
  justify-content: space-between;
  align-items: baseline; }

.logout-modal__close-icon {
  width: 15px;
  cursor: pointer; }

.logout-modal__content {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
  background: #0081ae !important;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff; }

.logout-modal__actions {
  background: #0081ae !important;
  border-top: none !important; }

* {
  font-family: "Open Sans", sans-serif; }

.ui.button {
  font-weight: 600 !important; }

input, textarea, .dropdown {
  font-size: 12px !important; }
  input:focus, textarea:focus, .dropdown:focus {
    border-color: #333333 !important; }

label, input, textarea {
  color: #333333 !important; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container::before {
    align-items: center;
    content: url(/static/media/calendar-icon.19752e55.svg);
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .react-datepicker-wrapper .react-datepicker__input-container > input {
    padding-left: 35px !important;
    text-align: left;
    height: 100%; }

.search-box {
  display: flex;
  margin-right: 20px !important; }
  .search-box .ui.basic.button {
    background-color: #38424a !important; }
    .search-box .ui.basic.button:active, .search-box .ui.basic.button:focus {
      background-color: #38424a !important; }
    .search-box .ui.basic.button:hover {
      background-color: #636a70 !important; }
  .search-box__search-type-dropdown {
    align-items: center;
    border: solid 1px #38424a;
    border-radius: unset !important;
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
    box-shadow: unset !important;
    display: flex !important;
    font-size: 13px !important;
    font-weight: 600;
    height: 40px;
    justify-content: space-between;
    margin-right: unset !important;
    min-width: 165px;
    padding: 10px !important;
    width: 165px; }
    .search-box__search-type-dropdown .icon {
      color: white !important; }
    .search-box__search-type-dropdown > .text {
      color: #ffffff;
      text-align: left; }
  .search-box .coordinate-search {
    border-radius: 4px !important; }
  .search-box__suggestion-dropdown {
    border-radius: unset !important;
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    border-left: none !important;
    font-size: 13px !important;
    font-weight: 600;
    height: 40px;
    min-width: 400px !important;
    padding-right: 35px !important; }
    .search-box__suggestion-dropdown__menu {
      max-height: 80vh !important;
      padding: unset !important; }
      .search-box__suggestion-dropdown__menu-divider {
        margin: unset !important; }
      .search-box__suggestion-dropdown__menu-header, .search-box__suggestion-dropdown__menu-footer {
        background-color: #ffffff;
        font-size: 10px !important;
        margin: unset !important;
        padding: 12px !important;
        position: -webkit-sticky;
        position: sticky;
        z-index: 99; }
      .search-box__suggestion-dropdown__menu-header {
        top: -1px; }
      .search-box__suggestion-dropdown__menu-footer {
        bottom: -1px;
        cursor: pointer; }
        .search-box__suggestion-dropdown__menu-footer span {
          float: right; }
      .search-box__suggestion-dropdown__menu-footer:hover {
        background-color: #f7f7f7; }
      .search-box__suggestion-dropdown__menu::-webkit-scrollbar {
        width: 5px; }
    .search-box__suggestion-dropdown > .text {
      font-weight: 600;
      vertical-align: sub; }
    .search-box__suggestion-dropdown input {
      font-family: "Open Sans", sans-serif !important;
      height: 40px;
      min-width: 400px !important; }
  .search-box .coordinate-search-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-size: 13px !important;
    font-weight: 600; }
    .search-box .coordinate-search-wrapper .input-wrapper {
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .search-box .coordinate-search-wrapper .input-wrapper input {
        margin: 10px; }
    .search-box .coordinate-search-wrapper .ui.button {
      background-color: #38424a;
      color: #ffffff; }

* {
  font-family: "Open Sans", sans-serif; }

.ui.button {
  font-weight: 600 !important; }

input, textarea, .dropdown {
  font-size: 12px !important; }
  input:focus, textarea:focus, .dropdown:focus {
    border-color: #333333 !important; }

label, input, textarea {
  color: #333333 !important; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container::before {
    align-items: center;
    content: url(/static/media/calendar-icon.19752e55.svg);
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .react-datepicker-wrapper .react-datepicker__input-container > input {
    padding-left: 35px !important;
    text-align: left;
    height: 100%; }

.basemap-buttons {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  display: block !important; }
  .basemap-buttons__button {
    padding-top: 2px;
    padding-bottom: 2px;
    background: #ffffff !important;
    color: #5c5c5c;
    font-size: 13px !important;
    font-family: "Open Sans", sans-serif !important; }
    .basemap-buttons__button:not(:last-child) {
      border-right: 1px solid #eaeaea !important; }
    .basemap-buttons__button.active {
      background-color: #38424a !important;
      color: #ffffff !important; }

.header--full-screen {
  border-radius: unset !important;
  margin: unset !important;
  padding: 0 5px 0 5px; }

.header__menu-item, .header__menu-brand {
  cursor: pointer;
  color: #7c8087 !important; }
  .header__menu-item::before, .header__menu-brand::before {
    background: none !important; }
  .header__menu-item > label, .header__menu-brand > label {
    padding-top: 6px; }
  .header__menu-item > img, .header__menu-brand > img {
    margin-right: 10px !important;
    width: unset !important; }

.header__menu-brand > img {
  height: 25px !important;
  margin-top: 0.3em !important; }

.header__menu-admin, .header__menu-map {
  display: flex !important;
  flex: 1 1 !important;
  justify-content: center;
  align-items: center;
  padding: unset !important; }
  .header__menu-admin::before, .header__menu-map::before {
    background: none !important; }

.header__menu-item {
  font-size: 13px;
  font-weight: 600 !important; }
  .header__menu-item__login-name {
    color: #39424a !important;
    pointer-events: none;
    font-weight: 600; }

.header__brand-label {
  font-weight: 600;
  color: #7c8087 !important; }

.footer--full-screen {
  background-color: #39424a !important;
  border-radius: unset !important;
  margin: unset !important;
  padding: 5px; }

.footer__menu-item {
  cursor: pointer;
  color: #ffffff !important;
  font-size: 13px;
  font-weight: 600 !important; }
  .footer__menu-item::before {
    background: none !important; }
  .footer__menu-item:hover {
    opacity: 0.5 !important; }

.footer__menu-copyright {
  color: #ffffff !important; }

.admin-site {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; }

#login-wrapper {
  display: flex; }

.administration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; }
  .administration * {
    font-family: "Open Sans", sans-serif !important; }
  .administration__actions-wrapper {
    margin-bottom: 15px;
    margin-left: 30px;
    display: flex;
    width: 450px;
    justify-content: space-between; }
    .administration__actions-wrapper .ui.button {
      font-size: 14px;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
      background: none; }
    .administration__actions-wrapper .ui.active.button {
      color: #551a8b;
      background: none; }
  .administration__content, .administration__details {
    height: calc(100vh - 80px - 10.75vh);
    margin-top: 80px; }
  .administration .header--full-screen {
    width: 100%;
    position: fixed;
    z-index: 2;
    height: 60px; }
  .administration .footer--full-screen {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 93px; }

#search-wrapper h3 {
  margin-left: 50px;
  margin-bottom: 20px; }

#search-wrapper th > .sort-icons-group {
  align-self: flex-end;
  margin-left: 10px; }
  #search-wrapper th > .sort-icons-group i {
    font-size: 16px;
    margin-right: 0 !important;
    font-family: 'Dropdown' !important; }

#search-wrapper .ui.grid {
  width: 100%;
  justify-content: flex-start;
  margin-left: 50px; }
  #search-wrapper .ui.grid .row.form-field__line {
    height: 60px;
    margin-bottom: 7px; }
  #search-wrapper .ui.grid .ui.form {
    display: flex; }
    #search-wrapper .ui.grid .ui.form .field {
      width: 400px;
      margin-right: 50px; }
      #search-wrapper .ui.grid .ui.form .field .name-wrapper {
        display: flex;
        flex-direction: row; }
      #search-wrapper .ui.grid .ui.form .field .dropdown-wrapper {
        justify-content: space-between;
        display: flex; }
  #search-wrapper .ui.grid .ui.dropdown > .dropdown.icon {
    font-family: Dropdown, sans-serif !important; }

#search-wrapper .ui.table {
  width: 100%; }
  #search-wrapper .ui.table th {
    padding: 7px;
    border-radius: 0 !important; }
  #search-wrapper .ui.table tr:hover {
    cursor: pointer; }
  #search-wrapper .ui.table tr td {
    color: #883639;
    font-weight: 600;
    font-size: 14px; }

#search-wrapper .search-users__search-controls {
  display: flex;
  justify-content: space-between;
  margin-left: 0 !important; }

#search-wrapper .search-users__table-wrapper {
  padding-top: 0;
  padding-bottom: 130px !important; }

#search-wrapper .search-users__table {
  margin: 0 0 10px 0 !important;
  width: 1245px; }

#search-wrapper .search-users--deleted {
  text-decoration: line-through; }

#search-wrapper .search-users__pagination {
  margin: 0 !important; }

#search-wrapper .pagination-control {
  border: none;
  box-shadow: none; }
  #search-wrapper .pagination-control a {
    text-decoration: underline; }
    #search-wrapper .pagination-control a:before {
      content: none; }
    #search-wrapper .pagination-control a.active {
      text-decoration: none;
      font-weight: 800; }
    #search-wrapper .pagination-control a[type="prevItem"], #search-wrapper .pagination-control a[type="nextItem"], #search-wrapper .pagination-control a[type="firstItem"], #search-wrapper .pagination-control a[type="lastItem"] {
      color: #883639;
      text-decoration: none; }

#add-account-wrapper {
  margin-left: 50px;
  overflow-y: scroll;
  margin-bottom: 100px; }
  #add-account-wrapper h3 {
    margin-top: 0px; }
  #add-account-wrapper .create-corporation, #add-account-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 25px;
    margin-right: 20px; }
  #add-account-wrapper .add-manager {
    justify-content: space-between;
    margin-right: 50px; }
    #add-account-wrapper .add-manager .validation-message {
      color: red;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px; }
  #add-account-wrapper .forms-wrapper {
    margin-top: 5px;
    display: flex;
    flex-direction: column; }
    #add-account-wrapper .forms-wrapper .field {
      display: flex;
      margin-bottom: 5px; }
      #add-account-wrapper .forms-wrapper .field .edit-field__title {
        height: 23px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center; }
      #add-account-wrapper .forms-wrapper .field .edit-field__value {
        height: 23px;
        width: 200px;
        border: 1px solid #a4bacc;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 0; }
      #add-account-wrapper .forms-wrapper .field .required {
        border-color: red; }
      #add-account-wrapper .forms-wrapper .field .license {
        width: 600px; }
  #add-account-wrapper .ui.dropdown {
    size: 10px;
    min-width: 40px;
    width: 76px;
    margin-left: 10px;
    height: 24px;
    align-items: center;
    border: 1px solid #a4bacc;
    border-radius: unset; }
    #add-account-wrapper .ui.dropdown .dropdown.icon {
      font-family: Dropdown, sans-serif !important;
      top: 2px;
      right: 10px; }
  #add-account-wrapper .ui.selection.dropdown {
    min-height: 22px;
    padding: 4px; }
  #add-account-wrapper .manager-header {
    margin-top: 10px; }

.color-picker {
  display: flex;
  position: relative;
  flex-direction: column; }
  .color-picker__swatch-button {
    background: #ffffff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    cursor: pointer;
    height: 30px;
    margin-top: 2px;
    padding: 5px;
    width: 100%; }
    .color-picker__swatch-button--required {
      border-color: #ff534c !important; }
    .color-picker__swatch-button--disabled {
      cursor: default; }
  .color-picker__popover {
    position: fixed;
    z-index: 2; }
    .color-picker__popover__cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .color-picker__popover .chrome-picker {
      border-radius: 5px !important;
      width: 188px !important;
      margin-top: 35px !important; }
      .color-picker__popover .chrome-picker input {
        padding: unset !important; }
      .color-picker__popover .chrome-picker .flexbox-fix:last-child {
        display: none !important; }

.draw-and-measure-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }
  .draw-and-measure-tools__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .draw-and-measure-tools__button img {
      height: 20px; }
    .draw-and-measure-tools__button:hover {
      opacity: 0.8; }
  .draw-and-measure-tools__button.active {
    background-color: #38424a !important; }
  .draw-and-measure-tools__modal {
    border-radius: 8px !important; }
    .draw-and-measure-tools__modal .header {
      height: 38px;
      padding: 10px 13px 10px 13px !important;
      border-bottom: unset !important; }
      .draw-and-measure-tools__modal .header label {
        font-weight: 600;
        font-size: 15px; }
    .draw-and-measure-tools__modal .content {
      display: flex !important;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
    .draw-and-measure-tools__modal .actions {
      background: unset !important;
      border-top: unset !important;
      display: flex;
      justify-content: space-between; }
      .draw-and-measure-tools__modal .actions button {
        border-radius: 20px;
        border: 2px solid #7c8087;
        background: unset;
        font-weight: 600 !important;
        height: 40px;
        margin: unset !important;
        width: 100px; }
        .draw-and-measure-tools__modal .actions button:hover {
          border-color: red; }
  .draw-and-measure-tools__button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important;
    border-radius: 10em !important; }
  .draw-and-measure-tools__button:not(:nth-child(3n)) {
    margin-right: 6px !important; }
  .draw-and-measure-tools__button.active {
    border-bottom: 2px solid #35d3f7 !important; }
  .draw-and-measure-tools .ui.disabled.button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important; }
  .draw-and-measure-tools__popup-input-text {
    padding: 3px 7px 7px 7px !important; }
    .draw-and-measure-tools__popup-input-text__content {
      display: flex;
      flex-direction: column; }
      .draw-and-measure-tools__popup-input-text__content .header {
        font-size: 12px !important;
        font-weight: 600 !important;
        margin-bottom: 5px !important; }
      .draw-and-measure-tools__popup-input-text__content .body textArea {
        border-radius: 5px !important;
        font-size: 12px !important;
        padding: 8px !important;
        resize: none !important;
        width: 100% !important; }
        .draw-and-measure-tools__popup-input-text__content .body textArea:focus {
          outline: unset; }
        .draw-and-measure-tools__popup-input-text__content .body textArea::-webkit-scrollbar {
          width: 5px; }
      .draw-and-measure-tools__popup-input-text__content .actions {
        display: flex;
        justify-content: space-around;
        margin-top: 5px; }
        .draw-and-measure-tools__popup-input-text__content .actions button {
          background: unset;
          border-radius: 20px;
          border: 2px solid #7c8087;
          font-size: 11px;
          font-weight: 600 !important;
          height: 27px;
          margin: 0 10px 0 10px;
          padding: 5px;
          width: 57px; }
          .draw-and-measure-tools__popup-input-text__content .actions button:hover {
            border-color: red; }

.draw-and-measure-options label {
  font-size: 11px !important;
  font-weight: 600 !important; }
  .draw-and-measure-options label::before {
    border-color: #7c8087 !important; }

.draw-and-measure-options__input-width input, .draw-and-measure-options__input-font-size input {
  height: 30px !important;
  margin-top: 2px !important; }

.add-edit-your-data-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }
  .add-edit-your-data-tools__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .add-edit-your-data-tools__button img {
      height: 20px; }
    .add-edit-your-data-tools__button:hover {
      opacity: 0.8; }
  .add-edit-your-data-tools__button.active {
    background-color: #38424a !important; }
  .add-edit-your-data-tools__button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important;
    border-radius: 10em !important; }
  .add-edit-your-data-tools__button:not(:nth-child(3n)) {
    margin-right: 6px !important; }
  .add-edit-your-data-tools__button.active {
    border-bottom: 2px solid #35d3f7 !important; }
  .add-edit-your-data-tools .ui.disabled.button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important; }

.add-edit-your-data__note {
  font-size: 12px;
  font-weight: 500;
  text-align: center; }

.configure-symbols {
  max-height: 377px;
  overflow: auto;
  padding-top: 25px !important; }

.configure-symbols__modal, .configure-symbol__modal {
  border-radius: 8px !important; }
  .configure-symbols__modal .header, .configure-symbol__modal .header {
    height: 38px;
    padding: 10px 13px 10px 13px !important;
    border-bottom: unset !important; }
    .configure-symbols__modal .header label, .configure-symbol__modal .header label {
      font-weight: 600;
      font-size: 15px; }
  .configure-symbols__modal .content, .configure-symbol__modal .content {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
  .configure-symbols__modal .actions, .configure-symbol__modal .actions {
    background: unset !important;
    border-top: unset !important;
    display: flex;
    justify-content: space-between; }
    .configure-symbols__modal .actions button, .configure-symbol__modal .actions button {
      border-radius: 20px;
      border: 2px solid #7c8087;
      background: unset;
      font-weight: 600 !important;
      height: 40px;
      margin: unset !important;
      width: 100px; }
      .configure-symbols__modal .actions button:hover, .configure-symbol__modal .actions button:hover {
        border-color: red; }

.configure-symbols::-webkit-scrollbar, .configure-symbol::-webkit-scrollbar {
  width: 5px; }

.configure-symbols__header, .configure-symbol__header {
  background: #ffffff;
  padding-bottom: 10px !important;
  position: absolute !important;
  top: -10px;
  z-index: 2; }

.configure-symbols__alpha.ui.icon.input .icon, .configure-symbol__alpha.ui.icon.input .icon {
  width: 25px !important; }

.configure-symbols__alpha.ui.icon.input input, .configure-symbol__alpha.ui.icon.input input {
  padding-right: 25px !important;
  cursor: pointer; }

.configure-symbols__color, .configure-symbol__color {
  height: 33px;
  margin: unset; }

.configure-symbols__add-button, .configure-symbol__add-button {
  background: #39424a !important;
  color: white !important;
  font-size: 12px !important;
  margin: 15px auto 0 !important;
  text-align: left !important;
  width: 30% !important; }
  .configure-symbols__add-button:hover, .configure-symbol__add-button:hover {
    opacity: 0.8; }

.configure-symbols__actions, .configure-symbol__actions {
  display: flex !important;
  justify-content: space-around; }
  .configure-symbols__actions__delete-button, .configure-symbols__actions__edit-button, .configure-symbol__actions__delete-button, .configure-symbol__actions__edit-button {
    background-color: white !important;
    border: 1px solid #eaeaea !important;
    padding: 8px !important; }
    .configure-symbols__actions__delete-button:hover, .configure-symbols__actions__edit-button:hover, .configure-symbol__actions__delete-button:hover, .configure-symbol__actions__edit-button:hover {
      background-color: #eaeaea !important; }

.configure-symbols__modal .label-title, .configure-symbol__modal .label-title {
  font-weight: 600;
  font-size: 16px; }

.configure-symbols__modal .label-note, .configure-symbol__modal .label-note {
  color: red !important;
  font-weight: 400;
  font-size: 11px;
  margin-bottom: 10px;
  text-align: center; }

.configure-symbols__modal-save > .content, .configure-symbol__modal-save > .content {
  align-items: unset !important; }

.configure-symbols .column, .configure-symbol .column {
  padding: 0 5px !important; }

.configure-symbols .row:not(.configure-symbols__header), .configure-symbol .row:not(.configure-symbols__header) {
  padding: 10px 0 0 0 !important; }

.configure-symbols .dropdown.icon, .configure-symbol .dropdown.icon {
  padding: 9px 5px !important; }

.configure-symbols .dropdown-style .menu.transition::-webkit-scrollbar, .configure-symbol .dropdown-style .menu.transition::-webkit-scrollbar {
  width: 5px; }

.configure-symbols .dropdown-style__selected-symbol, .configure-symbol .dropdown-style__selected-symbol {
  position: absolute;
  right: 34px;
  top: 6px;
  z-index: 1; }

.configure-symbols .dropdown-img-content, .configure-symbol .dropdown-img-content {
  align-items: center;
  display: flex;
  justify-content: center; }

.configure-symbols .ui.disabled.dropdown, .configure-symbols .ui.disabled.input, .configure-symbol .ui.disabled.dropdown, .configure-symbol .ui.disabled.input {
  opacity: 1 !important; }

.configure-symbols .required input, .configure-symbols .required .ui.dropdown, .configure-symbols .required .menu.transition, .configure-symbol .required input, .configure-symbol .required .ui.dropdown, .configure-symbol .required .menu.transition {
  border-color: #ff534c !important; }
  .configure-symbols .required input::-webkit-input-placeholder, .configure-symbols .required .ui.dropdown::-webkit-input-placeholder, .configure-symbols .required .menu.transition::-webkit-input-placeholder, .configure-symbol .required input::-webkit-input-placeholder, .configure-symbol .required .ui.dropdown::-webkit-input-placeholder, .configure-symbol .required .menu.transition::-webkit-input-placeholder {
    color: #ff534c !important; }
  .configure-symbols .required input:-ms-input-placeholder, .configure-symbols .required .ui.dropdown:-ms-input-placeholder, .configure-symbols .required .menu.transition:-ms-input-placeholder, .configure-symbol .required input:-ms-input-placeholder, .configure-symbol .required .ui.dropdown:-ms-input-placeholder, .configure-symbol .required .menu.transition:-ms-input-placeholder {
    color: #ff534c !important; }
  .configure-symbols .required input::-ms-input-placeholder, .configure-symbols .required .ui.dropdown::-ms-input-placeholder, .configure-symbols .required .menu.transition::-ms-input-placeholder, .configure-symbol .required input::-ms-input-placeholder, .configure-symbol .required .ui.dropdown::-ms-input-placeholder, .configure-symbol .required .menu.transition::-ms-input-placeholder {
    color: #ff534c !important; }
  .configure-symbols .required input::placeholder, .configure-symbols .required .ui.dropdown::placeholder, .configure-symbols .required .menu.transition::placeholder, .configure-symbol .required input::placeholder, .configure-symbol .required .ui.dropdown::placeholder, .configure-symbol .required .menu.transition::placeholder {
    color: #ff534c !important; }

.configure-symbols button, .configure-symbol button {
  margin: unset !important; }

.configure-symbols label, .configure-symbol label {
  font-size: 12px;
  font-weight: 700; }

.configure-data-symbols-tabs .ui.menu {
  box-shadow: none !important;
  border: none !important;
  font-size: 12px !important;
  justify-content: center;
  margin: auto;
  width: 60%;
  padding: unset; }
  .configure-data-symbols-tabs .ui.menu .item {
    background-color: #e9e8e7;
    height: 25px !important;
    justify-content: center;
    width: 100px; }
  .configure-data-symbols-tabs .ui.menu .item:hover {
    background-color: #e9e8e7;
    font-weight: 700; }
  .configure-data-symbols-tabs .ui.menu > .active.item, .configure-data-symbols-tabs .ui.menu .active.item:hover {
    font-weight: 700 !important;
    color: white;
    background: #39424a; }
  .configure-data-symbols-tabs .ui.menu .item:last-child {
    border-radius: 0 .28571429rem .28571429rem 0; }

.configure-data-symbols-tabs .ui.segment {
  border: none !important;
  box-shadow: none !important;
  margin-top: 5px !important;
  padding: unset !important; }

.configure-data-symbols-tabs .ui.dropdown, .configure-data-symbols-tabs .ui.input > input {
  font-size: 12px !important; }

.legend-group__contours {
  margin-top: 17px; }

.legend-group__items {
  margin-top: 5px !important; }

.legend-group__item {
  display: flex;
  align-items: center; }
  .legend-group__item img {
    height: 29px;
    width: 43px;
    margin-right: 10px; }
  .legend-group__item-header {
    font-size: 12px;
    font-weight: 600; }

.checkbox-group {
  margin-top: 4px; }
  .checkbox-group__main {
    font-size: 12px !important; }
  .checkbox-group__sublayers {
    margin: 3px 0 0 0;
    padding-left: 38px;
    font-size: 12px;
    list-style-type: none;
    display: flex;
    flex-direction: column; }
  .checkbox-group-item__sublayer {
    margin-bottom: 5px; }
    .checkbox-group-item__sublayer label {
      font-size: 12px !important; }
  .checkbox-group__title {
    display: flex;
    align-items: center;
    padding-bottom: 0 !important; }
    .checkbox-group__title label {
      font-size: 13px !important; }
    .checkbox-group__title.active {
      padding-bottom: 0 !important; }
  .checkbox-group__dropdown {
    font-family: Accordion, sans-serif !important; }
  .checkbox-group__content.active {
    padding-top: 5px !important; }
  .checkbox-group:not(.nested-group) .checkbox-group__sublayers .checkbox-group__title {
    padding: 0 !important; }

.nested-group {
  position: relative;
  left: -19px;
  margin: 0 0 5px !important; }
  .nested-group label {
    font-size: 12px !important; }
  .nested-group .checkbox-group__sublayers {
    padding-left: 26px; }

.layer-management__tabs .ui.menu {
  min-height: unset !important;
  font-size: 12px !important;
  height: 25px;
  border: none !important;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
  padding-right: 14px;
  padding-left: 14px; }
  .layer-management__tabs .ui.menu .item {
    border: none !important;
    flex-basis: 50% !important;
    justify-content: center;
    font-size: 11px;
    background: #f7f7f7;
    color: #39424a; }
  .layer-management__tabs .ui.menu .item:hover {
    font-weight: 700; }
  .layer-management__tabs .ui.menu .active.item, .layer-management__tabs .ui.menu .active.item:hover {
    font-weight: 700 !important;
    color: white;
    background: #39424a; }
  .layer-management__tabs .ui.menu .item:last-child {
    border-radius: 0 .28571429rem .28571429rem 0; }
  .layer-management__tabs .ui.menu .item:before {
    content: none !important; }

.layer-management__tab-item {
  padding: 0 0 5px 14px !important;
  border: none !important;
  margin-top: 5px !important;
  box-shadow: none !important;
  min-width: 314px; }

.layer-management-toolbox {
  max-height: 257px !important;
  overflow-y: auto; }
  .layer-management-toolbox .checkbox-group:first-of-type {
    margin-top: 0; }
  .layer-management-toolbox::-webkit-scrollbar {
    width: 5px; }

.layer-management-legends {
  max-height: 257px !important;
  overflow-y: auto; }
  .layer-management-legends img {
    display: block; }
  .layer-management-legends .legends__boundaries {
    height: 115px; }
  .layer-management-legends .legends__contour {
    margin-top: 10px;
    height: 120px; }
  .layer-management-legends::-webkit-scrollbar {
    width: 5px; }

.export {
  max-width: 350px !important; }

.export-data {
  font-size: 12px !important; }
  .export-data__modal {
    border-radius: 8px !important; }
    .export-data__modal .header {
      height: 38px;
      padding: 10px 13px 10px 13px !important;
      border-bottom: unset !important; }
      .export-data__modal .header label {
        font-weight: 600;
        font-size: 15px; }
    .export-data__modal .content {
      display: flex !important;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
    .export-data__modal .actions {
      background: unset !important;
      border-top: unset !important;
      display: flex;
      justify-content: space-between; }
      .export-data__modal .actions button {
        border-radius: 20px;
        border: 2px solid #7c8087;
        background: unset;
        font-weight: 600 !important;
        height: 40px;
        margin: unset !important;
        width: 100px; }
        .export-data__modal .actions button:hover {
          border-color: red; }
  .export-data .ui.basic.button {
    box-shadow: unset !important;
    text-decoration: underline;
    margin-left: 10px; }
  .export-data .ui.button {
    margin-top: 10px;
    height: 30px;
    padding: 8px; }
  .export-data .message {
    margin-top: 10px;
    margin-bottom: 5px; }
  .export-data .wrapper {
    display: flex;
    justify-content: center; }

.identify-features-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }
  .identify-features-tools__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .identify-features-tools__button img {
      height: 20px; }
    .identify-features-tools__button:hover {
      opacity: 0.8; }
  .identify-features-tools__button.active {
    background-color: #38424a !important; }
  .identify-features-tools__button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important;
    border-radius: 10em !important; }
  .identify-features-tools__button:not(:nth-child(3n)) {
    margin-right: 6px !important; }
  .identify-features-tools__button.active {
    border-bottom: 2px solid #35d3f7 !important; }
  .identify-features-tools .ui.disabled.button {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) !important; }

.identify-features__modal {
  border-radius: 8px !important; }
  .identify-features__modal .header {
    height: 38px;
    padding: 10px 13px 10px 13px !important;
    border-bottom: unset !important; }
    .identify-features__modal .header label {
      font-weight: 600;
      font-size: 15px; }
  .identify-features__modal .content {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
  .identify-features__modal .content {
    align-items: unset !important; }
    .identify-features__modal .content .list > .item {
      display: flex;
      align-items: center;
      height: 40px; }
      .identify-features__modal .content .list > .item img {
        border-radius: unset !important; }
  .identify-features__modal .actions {
    background: unset !important;
    border-top: unset !important;
    display: flex;
    justify-content: flex-end;
    padding-top: unset !important; }
    .identify-features__modal .actions button {
      border-radius: 20px;
      border: 2px solid #7c8087;
      background: unset;
      font-weight: 600 !important;
      height: 40px;
      margin: unset !important;
      width: 100px; }
      .identify-features__modal .actions button:hover {
        border-color: red; }

.notification-toast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px !important; }
  .notification-toast__icon {
    margin-right: 5px !important; }
  .notification-toast__message {
    font-size: 12px; }
  .notification-toast--hidden {
    display: none; }
  .notification-toast--show {
    display: flex; }

.toolbox {
  height: 100%;
  box-shadow: unset !important;
  margin-bottom: unset !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42px; }
  .toolbox__button-group {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    margin: 0 0 15px 0 !important; }
    .toolbox__button-group > :not(:last-child) {
      border-bottom: 1px solid #dce0e7 !important; }
    .toolbox__button-group > .toolbox__button {
      margin: unset !important;
      border-radius: unset !important; }
      .toolbox__button-group > .toolbox__button:first-child {
        border-top-left-radius: 7px !important;
        border-top-right-radius: 7px !important; }
      .toolbox__button-group > .toolbox__button:last-child {
        border-bottom-left-radius: 7px !important;
        border-bottom-right-radius: 7px !important; }
      .toolbox__button-group > .toolbox__button-configure-data-symbols img {
        height: 23px !important; }
  .toolbox__button {
    width: 42px !important;
    height: 41px;
    background-color: #ffffff !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px !important;
    margin-right: unset !important; }
    .toolbox__button img {
      height: 20px; }
    .toolbox__button:hover {
      opacity: 0.8; }
  .toolbox__button.active {
    background-color: #38424a !important; }
  .toolbox__portal {
    border-radius: 7px !important;
    margin: unset !important;
    max-width: 203px;
    min-width: 203px !important;
    padding: 10px 10px 10px 7px !important;
    position: absolute !important;
    top: 73px !important;
    left: 66px !important;
    width: 100%; }
    .toolbox__portal.layer-management {
      width: auto;
      max-width: unset; }
    .toolbox__portal.configure-data-symbols {
      max-width: 500px !important; }
    .toolbox__portal__title {
      display: flex;
      align-items: baseline;
      margin-bottom: 15px; }
      .toolbox__portal__title label {
        flex: 1 1;
        font-size: 13px;
        font-weight: 600; }
      .toolbox__portal__title img {
        width: 10px;
        cursor: pointer; }
    .toolbox__portal__content__placeholder-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center; }
      .toolbox__portal__content__placeholder-icons > .placeholder {
        border-radius: 10em !important;
        height: 41px;
        margin-top: unset !important;
        margin-bottom: 15px !important;
        width: 42px !important; }
      .toolbox__portal__content__placeholder-icons > .placeholder:not(:nth-child(3n)) {
        margin-right: 6px !important; }
      .toolbox__portal__content__placeholder-icons > .placeholder > .image {
        height: unset !important; }

.section {
  background-color: #ffffff;
  border-radius: 6px;
  height: 100%;
  padding: 10px 5px 10px 15px; }
  .section__form {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .section h3 {
    font-weight: 600;
    margin: 0 0 20px 0; }
  .section label {
    font-size: 12px !important; }

* {
  font-family: "Open Sans", sans-serif; }

.ui.button {
  font-weight: 600 !important; }

input, textarea, .dropdown {
  font-size: 12px !important; }
  input:focus, textarea:focus, .dropdown:focus {
    border-color: #333333 !important; }

label, input, textarea {
  color: #333333 !important; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container::before {
    align-items: center;
    content: url(/static/media/calendar-icon.19752e55.svg);
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .react-datepicker-wrapper .react-datepicker__input-container > input {
    padding-left: 35px !important;
    text-align: left;
    height: 100%; }

.section {
  height: calc(100% - 30px) !important; }

.section__form-add-edit-attributes__fields {
  overflow: auto;
  padding-right: 10px; }
  .section__form-add-edit-attributes__fields textArea {
    resize: none !important; }
  .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .menu::-webkit-scrollbar {
    width: 5px; }
  .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .dropdown-img-content {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .dropdown-img-content label {
      font-size: 13px !important; }
    .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .dropdown-img-content svg {
      margin-right: 10px; }
  .section__form-add-edit-attributes__fields .section__form__text-input-field-value1 .ui.input::before {
    align-items: center;
    color: #7c8087;
    content: '$';
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .section__form-add-edit-attributes__fields .section__form__text-input-field-value1 .ui.input input {
    padding-left: 35px; }
  .section__form-add-edit-attributes__fields::-webkit-scrollbar {
    width: 5px; }

.section__form-add-edit-attributes__actions {
  display: flex;
  justify-content: space-around;
  padding: 15px 5px 5px 5px; }
  .section__form-add-edit-attributes__actions button {
    background: unset !important;
    border: 2px solid #333333 !important;
    border-radius: 20px !important;
    height: 40px;
    margin: unset !important;
    width: 90px; }
    .section__form-add-edit-attributes__actions button:hover {
      background-color: #dce0e7 !important; }

.accordion__menu-item {
  padding: unset !important;
  width: 100% !important; }
  .accordion__menu-item .active.title {
    color: white;
    background: #39424a; }
    .accordion__menu-item .active.title .ui.header {
      color: #ffffff; }
    .accordion__menu-item .active.title .icon {
      color: #ffffff; }
  .accordion__menu-item__title {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
    padding: 12px !important; }
    .accordion__menu-item__title .ui.header {
      margin: unset; }
  .accordion__menu-item__content {
    padding: 12px 12px 12px 12px !important;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
        user-select: text; }

.owners .row {
  padding: 8px 0 !important; }

.owners .ui.header {
  margin-bottom: 5px; }

.owners .other-info {
  display: flex;
  justify-content: space-between; }

.owners .icon {
  cursor: pointer; }

.owners label {
  font-size: 11px; }

.owners span {
  font-weight: 700; }

.property-list {
  margin-top: 0 !important;
  padding: 18px 0 !important; }
  .property-list h5 {
    font-size: 12px;
    margin: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap; }
  .property-list .item {
    padding: unset !important; }
  .property-list__content {
    display: flex;
    align-items: center;
    padding: 0 10px; }
    .property-list__content .content {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px; }
      .property-list__content .content img {
        border-radius: unset !important; }
    .property-list__content .ui.button {
      background-color: #ebedef !important;
      margin: unset !important; }
    .property-list__content .image {
      margin-right: 13px !important;
      margin-left: 3px !important; }
  .property-list__empty {
    margin-top: 10px !important;
    padding: 0 10px !important;
    text-transform: uppercase !important; }
  .property-list__search-query {
    margin-top: 0 !important; }
  .property-list__pagination-control {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px 0; }
    .property-list__pagination-control__results-display {
      margin: 0 20px; }
    .property-list__pagination-control__first-page {
      margin-right: 5px; }
    .property-list__pagination-control__last-page {
      margin-left: 5px; }
    .property-list__pagination-control__first-page, .property-list__pagination-control__prev-page, .property-list__pagination-control__last-page, .property-list__pagination-control__next-page {
      font-size: 18px;
      cursor: pointer; }
    .property-list__pagination-control__first-page i.icon, .property-list__pagination-control__last-page i.icon {
      width: 5px; }
  .property-list__placeholder {
    margin: 15px; }

.property-details__fields {
  margin-top: 0 !important; }
  .property-details__fields > .row:first-child {
    padding-top: 0 !important; }

.property-details .row {
  padding-bottom: 10px !important; }

.property-details__legal-descriptions-label {
  margin-bottom: 3px !important; }

.property-details__parcel {
  margin-bottom: 5px !important; }
  .property-details__parcel > .row {
    padding-bottom: unset !important; }

.property-details label {
  font-size: 12px !important; }

.property-details .ui .column {
  padding: unset !important; }

.property-details .ui.label {
  line-height: 1.3em;
  background: unset;
  color: #636a70;
  font-size: 11px !important;
  margin: 5px 0 0 0 !important;
  padding: unset; }

.sales-information {
  padding: 10px 0; }
  .sales-information__field, .sales-information .row {
    padding: 0 !important; }
  .sales-information label {
    font-size: 12px !important;
    font-weight: 700; }
  .sales-information .ui.label {
    background: none; }

.memorial-details {
  padding-top: 10px;
  padding-bottom: 10px; }
  .memorial-details__group {
    padding-bottom: 7px !important; }
    .memorial-details__group .row {
      padding-bottom: 0 !important; }
      .memorial-details__group .row:nth-of-type(4) {
        padding-top: 7px !important; }
    .memorial-details__group:not(:first-of-type) {
      padding-top: 7px !important; }
    .memorial-details__group:not(:last-of-type) {
      border-bottom: 1px solid #e9e8e7; }
  .memorial-details .row:first-child {
    padding-top: 0 !important; }
  .memorial-details .row:nth-of-type(2) {
    padding-top: 5px !important; }
  .memorial-details__subheader {
    padding-left: 0 !important; }
    .memorial-details__subheader .ui.header {
      font-size: 12px; }
  .memorial-details__field--inline {
    display: inline-flex;
    padding-left: 0 !important; }
  .memorial-details__field--inline .field {
    display: flex;
    align-items: center; }
  .memorial-details__label-container.row {
    padding-top: 7px !important;
    display: block !important; }
  .memorial-details__label {
    display: block;
    font-weight: normal !important;
    padding-bottom: 5px !important;
    font-size: 11px !important;
    line-height: 1.2em;
    letter-spacing: 0.1px; }
  .memorial-details label:not(.memorial-details__label) {
    font-size: 12px !important;
    font-weight: 700; }
  .memorial-details .ui.label {
    background: none; }

.your-data label {
  font-size: 12px !important; }

.your-data .ui.grid > .row {
  padding-top: 14px !important;
  padding-left: 14px !important;
  padding-bottom: unset !important; }
  .your-data .ui.grid > .row .field {
    display: flex;
    align-items: center; }
    .your-data .ui.grid > .row .field label {
      margin: unset; }

.your-data .ui.label {
  background: unset;
  color: #636a70;
  font-size: 11px !important; }

.poi-details {
  padding-bottom: 15px; }
  .poi-details label {
    font-size: 11px !important;
    font-weight: 700; }
  .poi-details .ui .column {
    padding: unset !important; }
  .poi-details .ui.label {
    background: unset;
    color: #636a70;
    font-size: 11px !important;
    margin: 5px 0 0 0 !important;
    padding: unset;
    display: block; }

.qbe-data, .qbe-firestation, .qbe-hazards {
  margin-bottom: 20px; }
  .qbe-data label, .qbe-firestation label, .qbe-hazards label {
    font-size: 12px !important; }
  .qbe-data .ui.grid > .row, .qbe-firestation .ui.grid > .row, .qbe-hazards .ui.grid > .row {
    padding-top: 14px !important;
    padding-left: 14px !important;
    padding-bottom: unset !important; }
    .qbe-data .ui.grid > .row .field, .qbe-firestation .ui.grid > .row .field, .qbe-hazards .ui.grid > .row .field {
      display: flex;
      align-items: center; }
      .qbe-data .ui.grid > .row .field label, .qbe-firestation .ui.grid > .row .field label, .qbe-hazards .ui.grid > .row .field label {
        margin: unset; }
  .qbe-data .ui.label, .qbe-firestation .ui.label, .qbe-hazards .ui.label {
    background: unset;
    color: #636a70;
    font-size: 11px !important; }

.property {
  height: calc(100% - 25px); }
  .property__tab {
    height: inherit; }
    .property__tab .ui.menu {
      box-shadow: none !important;
      border: none !important;
      display: flex;
      margin: auto;
      justify-content: center;
      width: 60%; }
      .property__tab .ui.menu .item {
        background-color: #f7f7f7;
        color: #39424a;
        border: none;
        justify-content: center;
        width: 50%; }
      .property__tab .ui.menu > .item:last-child {
        border-radius: 0 .28571429rem .28571429rem 0; }
      .property__tab .ui.menu > .active.item, .property__tab .ui.menu > .active.item:hover {
        font-weight: 700 !important;
        color: white;
        background: #39424a; }
    .property__tab .ui.segment {
      height: inherit;
      overflow: hidden auto; }
      .property__tab .ui.segment::-webkit-scrollbar {
        width: 5px; }
    .property__tab .ui.tab {
      padding: unset;
      margin-top: 7px !important; }
    .property__tab .property-address {
      margin-top: 10px !important;
      text-align: center;
      padding: 0 10px !important;
      text-transform: uppercase !important; }
    .property__tab .reference, .property__tab .data-title {
      margin: 10px !important;
      text-align: left;
      padding: 0 10px !important; }
    .property__tab__placeholder {
      margin: 15px; }

.right-bar-pane {
  background-color: #e9e8e7 !important;
  border-radius: 6px !important;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2) !important;
  cursor: default;
  margin-bottom: unset !important;
  padding: 10px 7px 10px 7px !important;
  position: absolute !important;
  right: 16px;
  top: 60px;
  width: 380px; }
  .right-bar-pane__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    padding-right: 5px; }
    .right-bar-pane__header-title {
      flex: 1 1;
      font-weight: 700;
      font-size: 14px; }
    .right-bar-pane__header img {
      width: 10px;
      height: 10px;
      cursor: pointer;
      margin-top: 5px;
      margin-left: 5px; }

#map-view-wrapper {
  flex: 1 1; }
  #map-view-wrapper .esri-view-surface {
    z-index: 0; }
  #map-view-wrapper .esri-view-surface:focus::after {
    outline: none;
    box-shadow: none; }
  #map-view-wrapper .esri-attribution__powered-by {
    display: none; }
  #map-view-wrapper .esri-attribution__sources {
    font-size: 11px;
    font-weight: 700;
    outline: none; }
  #map-view-wrapper .esri-slider {
    border-radius: 7px;
    cursor: pointer;
    height: 200px;
    margin-bottom: 15px;
    padding: 15px 0 15px 0;
    width: 42px; }
    #map-view-wrapper .esri-slider__label {
      margin-left: 5px;
      color: red;
      font-size: 10px;
      width: 80px;
      line-height: 1.2; }
    #map-view-wrapper .esri-slider__thumb {
      border-color: #38424a;
      height: 17px;
      width: 17px; }
      #map-view-wrapper .esri-slider__thumb:hover, #map-view-wrapper .esri-slider__thumb:focus {
        cursor: inherit;
        background-color: #38424a; }
    #map-view-wrapper .esri-slider__segment-0 {
      background-color: #38424a; }
    #map-view-wrapper .esri-slider__thumb, #map-view-wrapper .esri-slider__label {
      outline: none; }

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; }

.faq-header__menu-top {
  height: 92px;
  margin: unset !important;
  padding: 0 300px; }
  .faq-header__menu-top > .item {
    color: #38424a !important;
    font-weight: 600 !important;
    margin: unset !important; }
  .faq-header__menu-top > .item:not(.faq-header__menu-top__logo) {
    margin-bottom: 15px !important; }
  .faq-header__menu-top__logo {
    flex: 1 1 !important;
    padding-left: 0 !important;
    padding-top: 1.5em !important; }
    .faq-header__menu-top__logo img {
      height: 60px;
      width: 200px !important;
      margin: -.75em !important; }

.faq-content {
  background-color: #f7f7f7 !important;
  margin: unset !important;
  padding: 45px 312px; }
  .faq-content p {
    text-align: justify !important; }
  .faq-content .table-header {
    background-color: #38424a !important;
    color: #ffffff !important;
    letter-spacing: 1px;
    font-weight: 500 !important; }
  .faq-content__title {
    font-family: "Open Sans", sans-serif !important; }
  .faq-content__question {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    width: 100%; }
  .faq-content__answer {
    font-size: 13px;
    font-weight: 400; }

.faq-footer {
  margin: unset !important; }
  .faq-footer label {
    font-size: 14px; }
  .faq-footer .divider {
    margin: 7px 0 !important; }
  .faq-footer__contact {
    padding: 55px 350px; }
    .faq-footer__contact h1 {
      color: #38424a !important; }
    .faq-footer__contact__left__title, .faq-footer__contact__right__title {
      color: #38424a !important;
      font-weight: 700 !important; }
    .faq-footer__contact__left {
      padding-right: 65px !important; }
      .faq-footer__contact__left__name {
        display: block;
        margin-bottom: 5px !important; }
      .faq-footer__contact__left__number {
        color: #ff534c !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        margin-top: 5px !important; }
        .faq-footer__contact__left__number span {
          color: #38424a;
          font-size: 12px !important;
          font-weight: 600 !important;
          margin: 0 15px; }
    .faq-footer__contact__right__schedule {
      display: flex;
      flex-wrap: wrap; }
      .faq-footer__contact__right__schedule label {
        font-weight: 600 !important;
        flex-basis: 50%;
        margin-bottom: 2px; }
      .faq-footer__contact__right__schedule__name {
        color: #7c8087 !important; }
  .faq-footer__about {
    background-color: #38424a !important;
    padding: 45px 250px; }
    .faq-footer__about p, .faq-footer__about label, .faq-footer__about h3 {
      color: #f7f7f7 !important; }
    .faq-footer__about h3 {
      font-weight: 500 !important; }
    .faq-footer__about button {
      margin: 0 5px 15px 0 !important; }
    .faq-footer__about__corelogic {
      font-weight: 500 !important; }
      .faq-footer__about__corelogic a {
        color: #f55d5d !important;
        text-decoration: underline !important; }
        .faq-footer__about__corelogic a:hover {
          color: red !important; }
    .faq-footer__about__data {
      margin-bottom: 10px !important; }
      .faq-footer__about__data .icon {
        margin-right: 10px !important; }
    .faq-footer__about__contact-us, .faq-footer__about__subscribe {
      color: #ffffff !important;
      background-color: #38424a !important;
      border: 2px solid #ffffff !important;
      border-radius: 5px !important;
      font-size: 13px !important; }
      .faq-footer__about__contact-us:hover, .faq-footer__about__subscribe:hover {
        opacity: 0.7 !important; }
    .faq-footer__about__divider {
      padding-top: 20px !important; }
      .faq-footer__about__divider > .column {
        padding: 0 100px !important; }
    .faq-footer__about__logo {
      height: 35px;
      width: 125px; }
    .faq-footer__about__copyright {
      font-size: 12px !important;
      padding: unset !important;
      margin-bottom: 5px; }
    .faq-footer__about__menu {
      font-size: 13px !important;
      padding-top: unset !important; }

#attributes-wrapper {
  margin-left: 50px;
  margin-top: 60px;
  font-weight: 600; }
  #attributes-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #attributes-wrapper .attribute-name-wrapper {
    display: flex;
    margin-bottom: 30px; }
    #attributes-wrapper .attribute-name-wrapper .attributes-options {
      margin-left: 20px; }
  #attributes-wrapper .add-attributes, #attributes-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 30px;
    margin-right: 20px; }
  #attributes-wrapper .edit-mode, #attributes-wrapper .theme, #attributes-wrapper .attribute-name-wrapper {
    display: flex;
    align-items: center; }
    #attributes-wrapper .edit-mode .ui.dropdown, #attributes-wrapper .theme .ui.dropdown, #attributes-wrapper .attribute-name-wrapper .ui.dropdown {
      font-weight: normal;
      width: 270px;
      margin-left: 10px;
      max-height: 28px;
      min-height: 28px;
      align-items: center;
      display: flex;
      border: 1px solid #a4bacc;
      border-radius: unset; }
      #attributes-wrapper .edit-mode .ui.dropdown .dropdown.icon, #attributes-wrapper .theme .ui.dropdown .dropdown.icon, #attributes-wrapper .attribute-name-wrapper .ui.dropdown .dropdown.icon {
        font-family: Dropdown, sans-serif !important;
        top: 4px; }
  #attributes-wrapper .edit-mode .ui.dropdown, #attributes-wrapper .attribute-name-wrapper .ui.dropdown {
    width: 200px; }
  #attributes-wrapper .additionals {
    display: flex;
    flex-direction: column; }
    #attributes-wrapper .additionals .ui.checkbox {
      font-weight: normal;
      margin-bottom: 20px; }

#add-manager-wrapper {
  margin-left: 50px;
  margin-top: 60px; }
  #add-manager-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #add-manager-wrapper .create-user, #add-manager-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 10px; }
  #add-manager-wrapper .add-manager {
    justify-content: space-between;
    margin-right: 50px; }
    #add-manager-wrapper .add-manager .validation-message {
      color: red;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px; }
  #add-manager-wrapper .forms-wrapper {
    margin-left: 10px;
    margin-top: 20px; }
    #add-manager-wrapper .forms-wrapper .field {
      display: flex;
      margin-bottom: 10px; }
      #add-manager-wrapper .forms-wrapper .field .edit-field__title {
        height: 28px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center; }
      #add-manager-wrapper .forms-wrapper .field .edit-field__value {
        height: 28px;
        width: 200px;
        border: 1px solid #a4bacc;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 0; }
      #add-manager-wrapper .forms-wrapper .field .required {
        border-color: red; }
  #add-manager-wrapper .ui.dropdown {
    min-width: 40px;
    width: 76px;
    margin-left: 10px;
    max-height: 28px;
    min-height: 28px;
    align-items: center;
    display: flex;
    border: 1px solid #a4bacc;
    border-radius: unset; }
    #add-manager-wrapper .ui.dropdown .dropdown.icon {
      font-family: Dropdown, sans-serif !important;
      top: 4px; }
  #add-manager-wrapper .address {
    margin-left: 10px; }

#add-user-wrapper {
  margin-left: 50px;
  margin-top: 60px; }
  #add-user-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #add-user-wrapper .create-user, #add-user-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 10px; }
  #add-user-wrapper .add-user {
    justify-content: space-between;
    margin-right: 50px; }
    #add-user-wrapper .add-user .validation-message {
      color: red;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px; }
  #add-user-wrapper .forms-wrapper {
    margin-left: 10px;
    margin-top: 20px; }
    #add-user-wrapper .forms-wrapper .field {
      display: flex;
      margin-bottom: 10px; }
      #add-user-wrapper .forms-wrapper .field .edit-field__title {
        height: 28px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center; }
      #add-user-wrapper .forms-wrapper .field .edit-field__value {
        height: 28px;
        width: 200px;
        border: 1px solid #a4bacc;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 0; }
      #add-user-wrapper .forms-wrapper .field .edit-field__checkbox {
        border: none !important; }
      #add-user-wrapper .forms-wrapper .field .required {
        border-color: red; }
  #add-user-wrapper .ui.dropdown {
    width: 50%;
    margin-left: 10px;
    max-height: 28px;
    min-height: 28px;
    align-items: center;
    display: flex;
    border: 1px solid #a4bacc;
    border-radius: unset; }
    #add-user-wrapper .ui.dropdown .dropdown.icon {
      font-family: Dropdown, sans-serif !important;
      top: 4px; }

#attributes-wrapper {
  margin-left: 50px;
  margin-top: 60px;
  font-weight: 600; }
  #attributes-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #attributes-wrapper .attribute-name-wrapper {
    display: flex;
    margin-bottom: 30px; }
    #attributes-wrapper .attribute-name-wrapper .attribute-name {
      margin-left: 20px; }
  #attributes-wrapper .update-attributes, #attributes-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 30px;
    margin-right: 20px; }
  #attributes-wrapper .edit-mode, #attributes-wrapper .theme {
    display: flex;
    align-items: center; }
    #attributes-wrapper .edit-mode .ui.dropdown, #attributes-wrapper .theme .ui.dropdown {
      font-weight: normal;
      width: 270px;
      margin-left: 10px;
      max-height: 28px;
      min-height: 28px;
      align-items: center;
      display: flex;
      border: 1px solid #a4bacc;
      border-radius: unset; }
      #attributes-wrapper .edit-mode .ui.dropdown .dropdown.icon, #attributes-wrapper .theme .ui.dropdown .dropdown.icon {
        font-family: Dropdown, sans-serif !important;
        top: 4px; }
  #attributes-wrapper .edit-mode .ui.dropdown {
    width: 180px; }
  #attributes-wrapper .additionals {
    display: flex;
    flex-direction: column; }
    #attributes-wrapper .additionals .ui.checkbox {
      font-weight: normal;
      margin-bottom: 20px; }

#edit-details-wrapper {
  margin-left: 50px;
  margin-top: 60px; }
  #edit-details-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 30px; }
  #edit-details-wrapper .update-details, #edit-details-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 10px; }
  #edit-details-wrapper .user-details {
    justify-content: space-between;
    margin-right: 50px; }
    #edit-details-wrapper .user-details .validation-message {
      color: red;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px; }
    #edit-details-wrapper .user-details .ui.dropdown {
      width: 50%;
      margin-left: 10px;
      max-height: 28px;
      min-height: 28px;
      align-items: center;
      display: flex;
      border: 1px solid #a4bacc;
      border-radius: unset; }
      #edit-details-wrapper .user-details .ui.dropdown .dropdown.icon {
        font-family: Dropdown, sans-serif !important;
        top: 4px; }
    #edit-details-wrapper .user-details .dropdown-wrapper {
      justify-content: space-between;
      display: flex; }
  #edit-details-wrapper .forms-wrapper {
    margin-left: 10px;
    margin-top: 20px; }
    #edit-details-wrapper .forms-wrapper .field {
      display: flex;
      margin-bottom: 10px; }
      #edit-details-wrapper .forms-wrapper .field .edit-field__title {
        height: 28px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center; }
      #edit-details-wrapper .forms-wrapper .field .edit-field__value {
        height: 28px;
        min-width: 280px;
        width: auto;
        border: 1px solid #a4bacc;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 0; }
      #edit-details-wrapper .forms-wrapper .field .disabled {
        border: none;
        color: #7c8087; }
      #edit-details-wrapper .forms-wrapper .field .required {
        border-color: red; }
      #edit-details-wrapper .forms-wrapper .field .address {
        margin-left: 10px; }
      #edit-details-wrapper .forms-wrapper .field .license {
        width: 600px; }
  #edit-details-wrapper .user-view .field .edit-field__value {
    width: 200px; }
  #edit-details-wrapper .corporation-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: -20px; }

#details-wrapper {
  margin-left: 50px; }
  #details-wrapper h3 {
    margin-bottom: 30px; }
  #details-wrapper .ui.basic.button {
    text-decoration: underline;
    box-shadow: unset;
    color: #5986f0 !important;
    padding: 0; }
  #details-wrapper .user-details .user-hierarchy-wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 50px; }
    #details-wrapper .user-details .user-hierarchy-wrapper .hierarchy-buttons {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 10px; }
      #details-wrapper .user-details .user-hierarchy-wrapper .hierarchy-buttons div {
        display: flex;
        align-items: center; }
        #details-wrapper .user-details .user-hierarchy-wrapper .hierarchy-buttons div .ui.basic.button {
          margin: 20px;
          color: black !important;
          font-size: 16px; }
        #details-wrapper .user-details .user-hierarchy-wrapper .hierarchy-buttons div .user-label {
          text-decoration: none; }
  #details-wrapper .field {
    display: flex;
    margin-bottom: 10px; }
    #details-wrapper .field .user-field__title {
      height: 25px;
      width: 200px;
      border: 1px dotted;
      padding-left: 5px;
      display: flex;
      align-items: center; }
    #details-wrapper .field .user-field__value {
      height: 25px;
      min-width: 280px;
      width: auto;
      border: 1px dotted;
      padding-left: 5px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      background-color: #f1f8fb; }
    #details-wrapper .field .billing {
      width: 450px; }
    #details-wrapper .field .license {
      min-width: 450px;
      width: auto; }
  #details-wrapper .edit-options {
    margin-top: 20px; }
    #details-wrapper .edit-options button {
      margin-right: 20px;
      font-size: 16px; }
  #details-wrapper .service-attributes h3 {
    margin-top: 20px;
    margin-bottom: 20px; }
  #details-wrapper .service-attributes .cell-wrapper {
    display: flex;
    align-items: baseline;
    padding-top: 5px; }
    #details-wrapper .service-attributes .cell-wrapper div {
      margin-left: 4px;
      margin-right: 4px; }
  #details-wrapper .service-attributes button {
    margin-bottom: 10px; }
  #details-wrapper .service-attributes .ui.table {
    width: 80%; }
    #details-wrapper .service-attributes .ui.table td {
      padding-top: 0;
      padding-bottom: 0; }

.webmap {
  height:100vh;
}

.logout-frame {
  position: absolute;
  height: 0;
  width: 0;
}

.modals.dimmer {
  z-index: 2000;
}
* {
  font-family: "Open Sans", sans-serif; }

.ui.button {
  font-weight: 600 !important; }

input, textarea, .dropdown {
  font-size: 12px !important; }
  input:focus, textarea:focus, .dropdown:focus {
    border-color: #333333 !important; }

label, input, textarea {
  color: #333333 !important; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container::before {
    align-items: center;
    content: url(/static/media/calendar-icon.19752e55.svg);
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .react-datepicker-wrapper .react-datepicker__input-container > input {
    padding-left: 35px !important;
    text-align: left;
    height: 100%; }

