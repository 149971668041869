@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.administration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; }
  .administration * {
    font-family: "Open Sans", sans-serif !important; }
  .administration__actions-wrapper {
    margin-bottom: 15px;
    margin-left: 30px;
    display: flex;
    width: 450px;
    justify-content: space-between; }
    .administration__actions-wrapper .ui.button {
      font-size: 14px;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
      background: none; }
    .administration__actions-wrapper .ui.active.button {
      color: #551a8b;
      background: none; }
  .administration__content, .administration__details {
    height: calc(100vh - 80px - 10.75vh);
    margin-top: 80px; }
  .administration .header--full-screen {
    width: 100%;
    position: fixed;
    z-index: 2;
    height: 60px; }
  .administration .footer--full-screen {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 93px; }
