@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
#add-account-wrapper {
  margin-left: 50px;
  overflow-y: scroll;
  margin-bottom: 100px; }
  #add-account-wrapper h3 {
    margin-top: 0px; }
  #add-account-wrapper .create-corporation, #add-account-wrapper .cancel {
    border: 1px solid #a4bacc;
    background-color: unset;
    border-radius: unset;
    height: 30px;
    margin-top: 25px;
    margin-right: 20px; }
  #add-account-wrapper .add-manager {
    justify-content: space-between;
    margin-right: 50px; }
    #add-account-wrapper .add-manager .validation-message {
      color: red;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px; }
  #add-account-wrapper .forms-wrapper {
    margin-top: 5px;
    display: flex;
    flex-direction: column; }
    #add-account-wrapper .forms-wrapper .field {
      display: flex;
      margin-bottom: 5px; }
      #add-account-wrapper .forms-wrapper .field .edit-field__title {
        height: 23px;
        width: 200px;
        border: 1px dotted;
        padding-left: 5px;
        display: flex;
        align-items: center; }
      #add-account-wrapper .forms-wrapper .field .edit-field__value {
        height: 23px;
        width: 200px;
        border: 1px solid #a4bacc;
        padding-left: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 0; }
      #add-account-wrapper .forms-wrapper .field .required {
        border-color: red; }
      #add-account-wrapper .forms-wrapper .field .license {
        width: 600px; }
  #add-account-wrapper .ui.dropdown {
    size: 10px;
    min-width: 40px;
    width: 76px;
    margin-left: 10px;
    height: 24px;
    align-items: center;
    border: 1px solid #a4bacc;
    border-radius: unset; }
    #add-account-wrapper .ui.dropdown .dropdown.icon {
      font-family: Dropdown, sans-serif !important;
      top: 2px;
      right: 10px; }
  #add-account-wrapper .ui.selection.dropdown {
    min-height: 22px;
    padding: 4px; }
  #add-account-wrapper .manager-header {
    margin-top: 10px; }
