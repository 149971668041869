@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

// Declare color variables here
$white: #ffffff;
$black: rgb(51, 51, 51);
$red: rgb(255, 0, 0);
$blue: rgb(0, 0, 255);
$zambezi: #5c5c5c;

$alice-blue: rgb(238,250,253);
$brick: rgb(136, 54, 57);
$box-shadow-1: rgba(0, 0, 0, 0.2);
$border-gray: #eaeaea;
$borderBlue: rgb(164, 186, 204);
$corn-flower-blue: rgb(89, 134, 240);
$dark-cyan: rgb(0,129,174);
$dark-grey: rgb(56, 66, 74);
$dark-grey-2: rgb(57, 66, 74);
$dim-gray: rgb(99,106,112);
$gainsboro: rgb(220,224,231);
$lavender: rgb(235,237,239);
$linen: rgb(233,232,231);
$medium-grey: rgb(124, 128, 135);
$medium-red: rgb(255, 83, 76);
$tomato: rgb(245,93,93);
$turquoise: rgb(53,211,247);
$whitesmoke: rgb(247,247,247);
// Font system
$dropdown-font: Dropdown, sans-serif;
$sans-serif: 'Open Sans', sans-serif;