@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.accordion__menu-item {
  padding: unset !important;
  width: 100% !important; }
  .accordion__menu-item .active.title {
    color: white;
    background: #39424a; }
    .accordion__menu-item .active.title .ui.header {
      color: #ffffff; }
    .accordion__menu-item .active.title .icon {
      color: #ffffff; }
  .accordion__menu-item__title {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
    padding: 12px !important; }
    .accordion__menu-item__title .ui.header {
      margin: unset; }
  .accordion__menu-item__content {
    padding: 12px 12px 12px 12px !important;
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text; }
