@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.your-data label {
  font-size: 12px !important; }

.your-data .ui.grid > .row {
  padding-top: 14px !important;
  padding-left: 14px !important;
  padding-bottom: unset !important; }
  .your-data .ui.grid > .row .field {
    display: flex;
    align-items: center; }
    .your-data .ui.grid > .row .field label {
      margin: unset; }

.your-data .ui.label {
  background: unset;
  color: #636a70;
  font-size: 11px !important; }
