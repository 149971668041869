@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.configure-symbols {
  max-height: 377px;
  overflow: auto;
  padding-top: 25px !important; }

.configure-symbols__modal, .configure-symbol__modal {
  border-radius: 8px !important; }
  .configure-symbols__modal .header, .configure-symbol__modal .header {
    height: 38px;
    padding: 10px 13px 10px 13px !important;
    border-bottom: unset !important; }
    .configure-symbols__modal .header label, .configure-symbol__modal .header label {
      font-weight: 600;
      font-size: 15px; }
  .configure-symbols__modal .content, .configure-symbol__modal .content {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
  .configure-symbols__modal .actions, .configure-symbol__modal .actions {
    background: unset !important;
    border-top: unset !important;
    display: flex;
    justify-content: space-between; }
    .configure-symbols__modal .actions button, .configure-symbol__modal .actions button {
      border-radius: 20px;
      border: 2px solid #7c8087;
      background: unset;
      font-weight: 600 !important;
      height: 40px;
      margin: unset !important;
      width: 100px; }
      .configure-symbols__modal .actions button:hover, .configure-symbol__modal .actions button:hover {
        border-color: red; }

.configure-symbols::-webkit-scrollbar, .configure-symbol::-webkit-scrollbar {
  width: 5px; }

.configure-symbols__header, .configure-symbol__header {
  background: #ffffff;
  padding-bottom: 10px !important;
  position: absolute !important;
  top: -10px;
  z-index: 2; }

.configure-symbols__alpha.ui.icon.input .icon, .configure-symbol__alpha.ui.icon.input .icon {
  width: 25px !important; }

.configure-symbols__alpha.ui.icon.input input, .configure-symbol__alpha.ui.icon.input input {
  padding-right: 25px !important;
  cursor: pointer; }

.configure-symbols__color, .configure-symbol__color {
  height: 33px;
  margin: unset; }

.configure-symbols__add-button, .configure-symbol__add-button {
  background: #39424a !important;
  color: white !important;
  font-size: 12px !important;
  margin: 15px auto 0 !important;
  text-align: left !important;
  width: 30% !important; }
  .configure-symbols__add-button:hover, .configure-symbol__add-button:hover {
    opacity: 0.8; }

.configure-symbols__actions, .configure-symbol__actions {
  display: flex !important;
  justify-content: space-around; }
  .configure-symbols__actions__delete-button, .configure-symbols__actions__edit-button, .configure-symbol__actions__delete-button, .configure-symbol__actions__edit-button {
    background-color: white !important;
    border: 1px solid #eaeaea !important;
    padding: 8px !important; }
    .configure-symbols__actions__delete-button:hover, .configure-symbols__actions__edit-button:hover, .configure-symbol__actions__delete-button:hover, .configure-symbol__actions__edit-button:hover {
      background-color: #eaeaea !important; }

.configure-symbols__modal .label-title, .configure-symbol__modal .label-title {
  font-weight: 600;
  font-size: 16px; }

.configure-symbols__modal .label-note, .configure-symbol__modal .label-note {
  color: red !important;
  font-weight: 400;
  font-size: 11px;
  margin-bottom: 10px;
  text-align: center; }

.configure-symbols__modal-save > .content, .configure-symbol__modal-save > .content {
  align-items: unset !important; }

.configure-symbols .column, .configure-symbol .column {
  padding: 0 5px !important; }

.configure-symbols .row:not(.configure-symbols__header), .configure-symbol .row:not(.configure-symbols__header) {
  padding: 10px 0 0 0 !important; }

.configure-symbols .dropdown.icon, .configure-symbol .dropdown.icon {
  padding: 9px 5px !important; }

.configure-symbols .dropdown-style .menu.transition::-webkit-scrollbar, .configure-symbol .dropdown-style .menu.transition::-webkit-scrollbar {
  width: 5px; }

.configure-symbols .dropdown-style__selected-symbol, .configure-symbol .dropdown-style__selected-symbol {
  position: absolute;
  right: 34px;
  top: 6px;
  z-index: 1; }

.configure-symbols .dropdown-img-content, .configure-symbol .dropdown-img-content {
  align-items: center;
  display: flex;
  justify-content: center; }

.configure-symbols .ui.disabled.dropdown, .configure-symbols .ui.disabled.input, .configure-symbol .ui.disabled.dropdown, .configure-symbol .ui.disabled.input {
  opacity: 1 !important; }

.configure-symbols .required input, .configure-symbols .required .ui.dropdown, .configure-symbols .required .menu.transition, .configure-symbol .required input, .configure-symbol .required .ui.dropdown, .configure-symbol .required .menu.transition {
  border-color: #ff534c !important; }
  .configure-symbols .required input::placeholder, .configure-symbols .required .ui.dropdown::placeholder, .configure-symbols .required .menu.transition::placeholder, .configure-symbol .required input::placeholder, .configure-symbol .required .ui.dropdown::placeholder, .configure-symbol .required .menu.transition::placeholder {
    color: #ff534c !important; }

.configure-symbols button, .configure-symbol button {
  margin: unset !important; }

.configure-symbols label, .configure-symbol label {
  font-size: 12px;
  font-weight: 700; }
