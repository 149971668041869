@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
.section {
  background-color: #ffffff;
  border-radius: 6px;
  height: 100%;
  padding: 10px 5px 10px 15px; }
  .section__form {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .section h3 {
    font-weight: 600;
    margin: 0 0 20px 0; }
  .section label {
    font-size: 12px !important; }
