@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
* {
  font-family: "Open Sans", sans-serif; }

.ui.button {
  font-weight: 600 !important; }

input, textarea, .dropdown {
  font-size: 12px !important; }
  input:focus, textarea:focus, .dropdown:focus {
    border-color: #333333 !important; }

label, input, textarea {
  color: #333333 !important; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container::before {
    align-items: center;
    content: url("~img/calendar-icon.svg");
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .react-datepicker-wrapper .react-datepicker__input-container > input {
    padding-left: 35px !important;
    text-align: left;
    height: 100%; }

.section {
  height: calc(100% - 30px) !important; }

.section__form-add-edit-attributes__fields {
  overflow: auto;
  padding-right: 10px; }
  .section__form-add-edit-attributes__fields textArea {
    resize: none !important; }
  .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .menu::-webkit-scrollbar {
    width: 5px; }
  .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .dropdown-img-content {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .dropdown-img-content label {
      font-size: 13px !important; }
    .section__form-add-edit-attributes__fields .section__form__dropdown-field-style .dropdown-img-content svg {
      margin-right: 10px; }
  .section__form-add-edit-attributes__fields .section__form__text-input-field-value1 .ui.input::before {
    align-items: center;
    color: #7c8087;
    content: '$';
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 35px; }
  .section__form-add-edit-attributes__fields .section__form__text-input-field-value1 .ui.input input {
    padding-left: 35px; }
  .section__form-add-edit-attributes__fields::-webkit-scrollbar {
    width: 5px; }

.section__form-add-edit-attributes__actions {
  display: flex;
  justify-content: space-around;
  padding: 15px 5px 5px 5px; }
  .section__form-add-edit-attributes__actions button {
    background: unset !important;
    border: 2px solid #333333 !important;
    border-radius: 20px !important;
    height: 40px;
    margin: unset !important;
    width: 90px; }
    .section__form-add-edit-attributes__actions button:hover {
      background-color: #dce0e7 !important; }
